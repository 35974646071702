<template>
  <div class="bleed-container">
    <div class="bleed-content" :class="containerClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'bleed-container',
  props: {
    bleed: {
      type: String,
      default: 'left'
    },
    containerClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    resizeContainer () {
      const offsetLeft = $(this.$el).offset().left
      const offsetRight = $(window).width() - ($(this.$el).offset().left + $(this.$el).width())
      if(this.bleed === 'left' || this.bleed === 'both') {
        $(this.$el).find('.bleed-content').css('margin-left', offsetLeft * -1)
        $(this.$el).find('.bleed-wrapper').css('margin-left', offsetLeft)
      }
      if(this.bleed === 'right' || this.bleed === 'both') {
        $(this.$el).find('.bleed-content').css('margin-right', offsetRight * -1)
        $(this.$el).find('.bleed-wrapper').css('margin-right', offsetRight)
      }
      if(this.bleed === 'right') {
        $(this.$el).find('.bleed-content, .bleed-wrapper').css('margin-left', 0)
      }
      if(this.bleed === 'left') {
        $(this.$el).find('.bleed-content, .bleed-wrapper').css('margin-right', 0)
      }
    }
  },
  mounted () {
    let self = this
    self.resizeContainer()
    $(window).resize(function () {
      self.resizeContainer()
    })
  }
}
</script>

<style lang="scss">
.bleed-container {
  position: relative;

  .bleed-content {
    position: relative;
    height: 100%;
  }
}
</style>
