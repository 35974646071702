<template>
  <header class="relative h-20 md:h-40 lg:h-60">
    <div class="main fixed left-0 right-0 top-0 z-50 h-20 md:h-40 lg:h-60 border-b border-white border-opacity-10" :class="[{'bg-black': !$route.meta.transparentHeader}]">
      <div class="outer-container">
        <div class="inner-container h-20 md:h-40 lg:h-60 flex flex-row justify-between items-center">
          <router-link to="/" class="brand">
            <svg id="Lockup_Connect_Copy" data-name="Lockup_Connect Copy" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="452" height="35" viewBox="0 0 452 35">
              <defs>
                <clipPath id="clip-path">
                  <path id="Clip_2" data-name="Clip 2" d="M0,0H31.1V34.5H0Z" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-2">
                  <path id="Clip_5" data-name="Clip 5" d="M0,0H33.7V34.5H0Z" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-4">
                  <path id="Clip_25" data-name="Clip 25" d="M0,0H9.315V9.659H0Z" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-5">
                  <path id="Clip_46" data-name="Clip 46" d="M0,0H7.032V9.525H0Z" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-6">
                  <path id="Clip_49" data-name="Clip 49" d="M0,0H8.562V9.659H0Z" fill="none"/>
                </clipPath>
                <clipPath id="clip-path-7">
                  <path id="Clip_56" data-name="Clip 56" d="M0,34.5H451.607V0H0Z" fill="none"/>
                </clipPath>
              </defs>
              <rect id="Lockup_Connect_background" data-name="Lockup_Connect background" width="452" height="35" fill="none"/>
              <g id="Group_59" data-name="Group 59" transform="translate(0 0.417)">
                <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                  <path id="Clip_2-2" data-name="Clip 2" d="M0,0H31.1V34.5H0Z" transform="translate(0 0)" fill="none"/>
                  <g id="Group_3-2" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">
                    <path id="Fill_1" data-name="Fill 1" d="M31.1,25.163a11.445,11.445,0,0,1-2.912,4.869,14.439,14.439,0,0,1-5.122,3.3,17.594,17.594,0,0,1-6.423,1.17,16.243,16.243,0,0,1-14.481-8.52A17.9,17.9,0,0,1,0,17.251a18.138,18.138,0,0,1,1.278-6.8A16.9,16.9,0,0,1,4.754,4.962a16,16,0,0,1,5.3-3.628A16.475,16.475,0,0,1,16.645,0a16.561,16.561,0,0,1,8.944,2.446,11.448,11.448,0,0,1,5.145,6.285l-6.216,3.02A7.452,7.452,0,0,0,21.5,8.216a8.964,8.964,0,0,0-4.857-1.311A8.8,8.8,0,0,0,9.808,9.831a10.637,10.637,0,0,0-2.648,7.42,10.661,10.661,0,0,0,2.659,7.42,8.775,8.775,0,0,0,6.826,2.95,8.847,8.847,0,0,0,5.11-1.475A7.33,7.33,0,0,0,24.7,22.19Z" transform="translate(0 0)" fill="#fff"/>
                  </g>
                </g>
                <g id="Group_6" data-name="Group 6" transform="translate(158.731)">
                  <path id="Clip_5-2" data-name="Clip 5" d="M0,0H33.7V34.5H0Z" transform="translate(0 0)" fill="none"/>
                  <g id="Group_6-2" data-name="Group 6" transform="translate(0 0)" clip-path="url(#clip-path-2)">
                    <path id="Fill_4" data-name="Fill 4" d="M16.829,34.5a17.9,17.9,0,0,1-4.521-.562,16.333,16.333,0,0,1-7.517-4.36,16.468,16.468,0,0,1-2.6-3.529,17.218,17.218,0,0,1-1.64-4.178,19.821,19.821,0,0,1,0-9.246A17.232,17.232,0,0,1,2.187,8.45a16.476,16.476,0,0,1,2.6-3.528A16.335,16.335,0,0,1,12.308.562a18.479,18.479,0,0,1,9.044,0A16.19,16.19,0,0,1,31.5,8.45a17.194,17.194,0,0,1,1.648,4.179,19.713,19.713,0,0,1,0,9.246A17.18,17.18,0,0,1,31.5,26.052a16.512,16.512,0,0,1-2.616,3.529,16.423,16.423,0,0,1-7.537,4.36A17.93,17.93,0,0,1,16.829,34.5Zm0-27.6A9.093,9.093,0,0,0,9.922,9.866,10.456,10.456,0,0,0,7.16,17.251a10.5,10.5,0,0,0,2.762,7.4,9.075,9.075,0,0,0,6.906,2.973,9.077,9.077,0,0,0,6.907-2.973,10.5,10.5,0,0,0,2.762-7.4,10.458,10.458,0,0,0-2.762-7.385A9.1,9.1,0,0,0,16.829,6.905Z" transform="translate(0 0)" fill="#fff"/>
                  </g>
                </g>
                <path id="Fill_7" data-name="Fill 7" d="M0,0H5.94L19.361,19.569V0h6.975V33.567H20.489L6.975,14V33.567H0Z" transform="translate(225.285 0.468)" fill="#fff"/>
                <path id="Fill_9" data-name="Fill 9" d="M0,0H5.94L19.361,19.569V0h6.976V33.567H20.489L6.975,14V33.567H0Z" transform="translate(260.255 0.468)" fill="#fff"/>
                <path id="Fill_11" data-name="Fill 11" d="M0,0H20.212V6.812H6.975v6.039H18.762v6.812H6.975v7.093H20.212v6.812H0Z" transform="translate(320.386 0.468)" fill="#fff"/>
                <g id="Group_15" data-name="Group 15" transform="translate(353.583)">
                  <path id="Clip_14-2" data-name="Clip 14" d="M0,0H31.1V34.5H0Z" transform="translate(0 0)" fill="none"/>
                  <g id="Group_15-2" data-name="Group 15" transform="translate(0 0)" clip-path="url(#clip-path)">
                    <path id="Fill_13" data-name="Fill 13" d="M31.1,25.163a11.446,11.446,0,0,1-2.912,4.869,14.439,14.439,0,0,1-5.122,3.3,17.594,17.594,0,0,1-6.423,1.17,16.243,16.243,0,0,1-14.481-8.52A17.9,17.9,0,0,1,0,17.251a18.14,18.14,0,0,1,1.278-6.8A16.9,16.9,0,0,1,4.754,4.962a16.018,16.018,0,0,1,5.3-3.628A16.483,16.483,0,0,1,16.645,0a16.561,16.561,0,0,1,8.944,2.446,11.448,11.448,0,0,1,5.145,6.285l-6.216,3.02A7.454,7.454,0,0,0,21.5,8.216a8.962,8.962,0,0,0-4.857-1.311A8.8,8.8,0,0,0,9.807,9.831a10.639,10.639,0,0,0-2.648,7.42,10.662,10.662,0,0,0,2.659,7.42,8.775,8.775,0,0,0,6.826,2.95,8.847,8.847,0,0,0,5.11-1.475A7.333,7.333,0,0,0,24.7,22.19Z" transform="translate(0 0)" fill="#fff"/>
                  </g>
                </g>
                <path id="Fill_16" data-name="Fill 16" d="M26.267,0V6.812H16.6V33.567H9.623V6.812H0V0Z" transform="translate(425.34 0.468)" fill="#fff"/>
                <path id="Fill_18" data-name="Fill 18" d="M5.159,0V.966H.99V3.89H4.71v.966H.99V8.425H5.159V9.39H0V0Z" transform="translate(61.275 0.134)" fill="#fff"/>
                <path id="Fill_20" data-name="Fill 20" d="M6.359,9.39,3.76,5.42,1.161,9.39H0L3.18,4.534.211,0H1.4L3.773,3.635,6.148,0H7.309L4.354,4.521,7.533,9.39Z" transform="translate(66.961 0.134)" fill="#fff"/>
                <path id="Fill_22" data-name="Fill 22" d="M.99,9.39H0V0H3.549A2.654,2.654,0,0,1,6.28,2.7a2.661,2.661,0,0,1-2.731,2.71H.99V9.39ZM.99.966V4.44h2.56A1.718,1.718,0,0,0,5.278,2.7,1.691,1.691,0,0,0,3.549.966Z" transform="translate(75.338 0.134)" fill="#fff"/>
                <g id="Group_26" data-name="Group 26" transform="translate(82.172)">
                  <path id="Clip_25-2" data-name="Clip 25" d="M0,0H9.315V9.659H0Z" transform="translate(0 0)" fill="none"/>
                  <g id="Group_26-2" data-name="Group 26" transform="translate(0 0)" clip-path="url(#clip-path-4)">
                    <path id="Fill_24" data-name="Fill 24" d="M4.658,9.659a4.546,4.546,0,0,1-3.314-1.4A4.949,4.949,0,0,1,0,4.8,4.865,4.865,0,0,1,1.344,1.385,4.572,4.572,0,0,1,4.658,0,4.571,4.571,0,0,1,7.971,1.385,4.866,4.866,0,0,1,9.315,4.8,4.95,4.95,0,0,1,7.971,8.257,4.545,4.545,0,0,1,4.658,9.659Zm0-8.679A3.69,3.69,0,0,0,1.029,4.8,3.719,3.719,0,0,0,4.658,8.68,3.718,3.718,0,0,0,8.285,4.8,3.689,3.689,0,0,0,4.658.979Z" transform="translate(0 0)" fill="#fff"/>
                  </g>
                </g>
                <path id="Fill_27" data-name="Fill 27" d="M5.159,0V.966H.99V3.89H4.71v.966H.99V8.425H5.159V9.39H0V0Z" transform="translate(61.275 12.556)" fill="#fff"/>
                <path id="Fill_29" data-name="Fill 29" d="M6.359,9.39,3.76,5.42l-2.6,3.97H0L3.18,4.534.211,0H1.4L3.773,3.635,6.148,0H7.309L4.354,4.521,7.533,9.39Z" transform="translate(66.961 12.556)" fill="#fff"/>
                <path id="Fill_31" data-name="Fill 31" d="M.99,9.39H0V0H3.549A2.654,2.654,0,0,1,6.28,2.7a2.661,2.661,0,0,1-2.731,2.71H.99V9.39ZM.99.966V4.44h2.56A1.718,1.718,0,0,0,5.278,2.7,1.691,1.691,0,0,0,3.549.966Z" transform="translate(75.338 12.556)" fill="#fff"/>
                <path id="Fill_33" data-name="Fill 33" d="M.989,0V8.425H5.66V9.39H0V0Z" transform="translate(82.7 12.556)" fill="#fff"/>
                <path id="Fill_35" data-name="Fill 35" d="M4.657,9.659a4.545,4.545,0,0,1-3.313-1.4A4.95,4.95,0,0,1,0,4.8,4.866,4.866,0,0,1,1.344,1.385,4.571,4.571,0,0,1,4.657,0,4.571,4.571,0,0,1,7.971,1.385,4.866,4.866,0,0,1,9.315,4.8,4.95,4.95,0,0,1,7.971,8.257,4.545,4.545,0,0,1,4.657,9.659Zm0-8.679A3.69,3.69,0,0,0,1.029,4.8,3.719,3.719,0,0,0,4.657,8.68,3.719,3.719,0,0,0,8.285,4.8,3.69,3.69,0,0,0,4.657.979Z" transform="translate(88.426 12.422)" fill="#fff"/>
                <path id="Fill_37" data-name="Fill 37" d="M.989,9.391H0V0H3.338a2.769,2.769,0,0,1,1.94.76,2.608,2.608,0,0,1,.792,1.9,2.769,2.769,0,0,1-.592,1.783A2.561,2.561,0,0,1,3.9,5.326L6.6,9.39H5.4L2.81,5.366H.989V9.39Zm0-8.425V4.4H3.312A1.692,1.692,0,0,0,5.066,2.656,1.667,1.667,0,0,0,3.312.966Z" transform="translate(98.992 12.556)" fill="#fff"/>
                <path id="Fill_39" data-name="Fill 39" d="M5.159,0V.966H.99V3.89H4.71v.966H.99V8.425H5.159V9.39H0V0Z" transform="translate(106.525 12.556)" fill="#fff"/>
                <path id="Fill_41" data-name="Fill 41" d="M5.159,0V.966H.99V3.89H4.71v.966H.99V8.425H5.159V9.39H0V0Z" transform="translate(61.275 24.978)" fill="#fff"/>
                <path id="Fill_43" data-name="Fill 43" d="M2.863,9.39H0V0H2.863A4.485,4.485,0,0,1,6.092,1.316,4.7,4.7,0,0,1,7.4,4.655,4.829,4.829,0,0,1,6.092,8.034,4.435,4.435,0,0,1,2.863,9.39ZM.989.966V8.425H2.915a3.544,3.544,0,0,0,3.457-3.77A3.461,3.461,0,0,0,2.915.966Z" transform="translate(67.779 24.978)" fill="#fff"/>
                <g id="Group_47" data-name="Group 47" transform="translate(76.433 24.978)">
                  <path id="Clip_46-2" data-name="Clip 46" d="M0,0H7.032V9.525H0Z" transform="translate(0)" fill="none"/>
                  <g id="Group_47-2" data-name="Group 47" transform="translate(0)" clip-path="url(#clip-path-5)">
                    <path id="Fill_45" data-name="Fill 45" d="M0,5.876V0H.99V5.876a2.524,2.524,0,1,0,5.04,0V0h1V5.876A3.488,3.488,0,0,1,3.509,9.525,3.485,3.485,0,0,1,0,5.876" fill="#fff"/>
                  </g>
                </g>
                <g id="Group_50" data-name="Group 50" transform="translate(84.718 24.843)">
                  <path id="Clip_49-2" data-name="Clip 49" d="M0,0H8.562V9.659H0Z" transform="translate(0)" fill="none"/>
                  <g id="Group_50-2" data-name="Group 50" transform="translate(0)" clip-path="url(#clip-path-6)">
                    <path id="Fill_48" data-name="Fill 48" d="M4.618,9.659A4.62,4.62,0,0,1,0,4.8,4.6,4.6,0,0,1,4.618,0,4.082,4.082,0,0,1,8.457,2.2l-.884.443A3.2,3.2,0,0,0,4.618.98,3.587,3.587,0,0,0,1.029,4.8,3.618,3.618,0,0,0,4.618,8.68,3.167,3.167,0,0,0,7.665,6.815l.9.416A4.065,4.065,0,0,1,4.618,9.659" fill="#fff"/>
                  </g>
                </g>
                <path id="Fill_51" data-name="Fill 51" d="M1.055,9.391H0L3.694,0h.9L8.3,9.39H7.217L6.319,7.1H1.952l-.9,2.294Zm3.087-7.9L2.335,6.131H5.95Z" transform="translate(93.531 24.978)" fill="#fff"/>
                <path id="Fill_53" data-name="Fill 53" d="M7.059.966H4.025V9.39h-.99V.966H0V0H7.059Z" transform="translate(100.589 24.978)" fill="#fff"/>
                <path id="Clip_56-2" data-name="Clip 56" d="M0,34.5H451.607V0H0Z" fill="none"/>
                <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path-7)">
                  <path id="Fill_55" data-name="Fill 55" d="M0,9.39H.99V0H0Z" transform="translate(108.636 24.978)" fill="#fff"/>
                  <path id="Fill_57" data-name="Fill 57" d="M4.657,9.659a4.545,4.545,0,0,1-3.313-1.4A4.95,4.95,0,0,1,0,4.8,4.866,4.866,0,0,1,1.344,1.385,4.571,4.571,0,0,1,4.657,0,4.571,4.571,0,0,1,7.971,1.385,4.866,4.866,0,0,1,9.315,4.8,4.95,4.95,0,0,1,7.971,8.257,4.545,4.545,0,0,1,4.657,9.659Zm0-8.68A3.69,3.69,0,0,0,1.029,4.8,3.719,3.719,0,0,0,4.657,8.68,3.719,3.719,0,0,0,8.285,4.8,3.69,3.69,0,0,0,4.657.979Z" transform="translate(110.879 24.844)" fill="#fff"/>
                  <path id="Fill_58" data-name="Fill 58" d="M.937,0,6.108,7.539V0h1V9.39H6.187L.99,1.865V9.39H0V0Z" transform="translate(121.446 24.978)" fill="#fff"/>
                </g>
              </g>
            </svg>

          </router-link>
          <div class="right-links space-x-3 md:space-x-12 flex flex-row items-center text-14">
            <div class="waus-logo">
              <svg xmlns="http://www.w3.org/2000/svg" width="181" height="35" viewBox="0 0 181 35">
                <g id="Lockup_AustralianWineMOW" transform="translate(0)">
                  <rect id="Lockup_AustralianWineMOW_White_background" data-name="Lockup_AustralianWineMOW (White) background" width="181" height="35" fill="none"/>
                  <path id="Combined_Shape" data-name="Combined Shape" d="M3.21,14.684H0L5.684,0H8.138l5.7,14.682H10.551l-.9-2.454H4.131L3.21,14.683Zm3.7-9.817-1.779,4.7H8.65Z" transform="translate(0 0.803)" fill="#fff"/>
                  <path id="Fill_2" data-name="Fill 2" d="M0,9V0H3.026V9a2.7,2.7,0,0,0,2.658,2.853A2.68,2.68,0,0,0,8.323,9V0h3.026V9a5.615,5.615,0,0,1-5.664,5.873A5.619,5.619,0,0,1,0,9" transform="translate(14.622 0.803)" fill="#fff"/>
                  <path id="Fill_4" data-name="Fill 4" d="M9.222,4.7A5.433,5.433,0,0,0,5.6,3c-1.022,0-1.717.482-1.717,1.2,0,2.5,7.32.986,7.32,6.334,0,2.328-1.922,4.573-5.275,4.573A8.041,8.041,0,0,1,0,12.313l1.942-2.266A5.358,5.358,0,0,0,5.93,12.082c1.288,0,2.167-.587,2.167-1.531C8.1,8.076.838,9.712.838,4.426.838,1.93,2.9,0,5.848,0A7.36,7.36,0,0,1,10.98,2.244Z" transform="translate(27.465 0.593)" fill="#fff"/>
                  <path id="Fill_7" data-name="Fill 7" d="M11.389,2.979H7.2v11.7H4.172V2.979H0V0H11.389Z" transform="translate(39.714 0.803)" fill="#fff"/>
                  <path id="Fill_9" data-name="Fill 9" d="M3.026,14.684H0V0H5.521A4.549,4.549,0,0,1,8.685,1.322a4.486,4.486,0,0,1,1.355,3.21A4.339,4.339,0,0,1,7.749,8.6l3.742,6.082H7.811l-3.23-5.6H3.026v5.6ZM3.026,3V6.4H5.214a1.7,1.7,0,1,0,0-3.4Z" transform="translate(53.027 0.803)" fill="#fff"/>
                  <path id="Fill_11" data-name="Fill 11" d="M3.211,14.684H0L5.684,0H8.138l5.7,14.682H10.551l-.9-2.454H4.131l-.92,2.454Zm3.7-9.817-1.779,4.7H8.649Z" transform="translate(65.399 0.803)" fill="#fff"/>
                  <path id="Fill_13" data-name="Fill 13" d="M3.026,0V11.7H9.59v2.979H0V0Z" transform="translate(80.859 0.803)" fill="#fff"/>
                  <path id="Fill_15" data-name="Fill 15" d="M0,14.683H3.026V0H0Z" transform="translate(92.455 0.803)" fill="#fff"/>
                  <path id="Fill_17" data-name="Fill 17" d="M3.211,14.684H0L5.685,0H8.138l5.7,14.682H10.551l-.9-2.454H4.131l-.92,2.454Zm3.7-9.817-1.779,4.7H8.649l-1.738-4.7Z" transform="translate(97.096 0.803)" fill="#fff"/>
                  <path id="Fill_18" data-name="Fill 18" d="M2.577,0,8.4,8.558V0H11.43V14.683H8.9L3.026,6.125v8.558H0V0Z" transform="translate(112.557 0.803)" fill="#fff"/>
                  <path id="Fill_19" data-name="Fill 19" d="M20.407,0,15.663,14.7H13.23L10.224,6.188,7.239,14.7H4.785L0,0H3.231L6.073,9.46,9.2,0h2.107l3.128,9.46L17.238,0Z" transform="translate(130.225 0.782)" fill="#fff"/>
                  <path id="Fill_20" data-name="Fill 20" d="M0,14.683H3.026V0H0Z" transform="translate(152.312 0.803)" fill="#fff"/>
                  <path id="Fill_21" data-name="Fill 21" d="M2.577,0,8.4,8.558V0h3.027V14.683H8.9L3.026,6.125v8.558H0V0Z" transform="translate(157.915 0.803)" fill="#fff"/>
                  <path id="Fill_22" data-name="Fill 22" d="M8.772,0V2.979H3.026V5.622H8.139V8.6H3.026v3.1H8.772v2.979H0V0Z" transform="translate(171.922 0.803)" fill="#fff"/>
                  <path id="Fill_23" data-name="Fill 23" d="M0,0H3.681L7.934,10.11,12.391,0h3.088V14.683H12.453V6.062l-3.66,8.621H6.6L3.026,6.062v8.621H0Z" transform="translate(0.961 19.932)" fill="#E8490F"/>
                  <path id="Fill_24" data-name="Fill 24" d="M3.211,14.684H0L5.684,0H8.138l5.7,14.682H10.551l-.9-2.454H4.131l-.92,2.454Zm3.7-9.817-1.779,4.7H8.649Z" transform="translate(18.057 19.932)" fill="#E8490F"/>
                  <path id="Fill_25" data-name="Fill 25" d="M4.907,14.683H0V0H4.907a7.247,7.247,0,0,1,5.179,2.074,7.279,7.279,0,0,1,2.081,5.2,7.473,7.473,0,0,1-2.081,5.3A7.187,7.187,0,0,1,4.907,14.683ZM3.026,2.979V11.7H5.112c2.275,0,3.926-1.861,3.926-4.426a3.99,3.99,0,0,0-3.926-4.3Z" transform="translate(33.517 19.931)" fill="#E8490F"/>
                  <path id="Fill_26" data-name="Fill 26" d="M8.772,0V2.979H3.026V5.622H8.138V8.6H3.026v3.1H8.772v2.979H0V0Z" transform="translate(47.853 19.932)" fill="#E8490F"/>
                  <path id="Fill_27" data-name="Fill 27" d="M7.3,15.1A7.266,7.266,0,0,1,0,7.551,7.266,7.266,0,0,1,7.3,0a7.275,7.275,0,0,1,7.32,7.551A7.275,7.275,0,0,1,7.3,15.1Zm0-12.082a4.275,4.275,0,0,0-4.192,4.53A4.275,4.275,0,0,0,7.3,12.082a4.275,4.275,0,0,0,4.192-4.531A4.275,4.275,0,0,0,7.3,3.021Z" transform="translate(63.088 19.722)" fill="#E8490F"/>
                  <path id="Fill_28" data-name="Fill 28" d="M0,9V0H3.026V9a2.7,2.7,0,0,0,2.658,2.853A2.68,2.68,0,0,0,8.323,9V0h3.026V9a5.615,5.615,0,0,1-5.664,5.873A5.619,5.619,0,0,1,0,9" transform="translate(79.877 19.932)" fill="#E8490F"/>
                  <path id="Fill_29" data-name="Fill 29" d="M3.026,14.684H0V0H5.52A4.549,4.549,0,0,1,8.685,1.322a4.487,4.487,0,0,1,1.355,3.21A4.34,4.34,0,0,1,7.749,8.6l3.741,6.082H7.811l-3.23-5.6H3.026v5.6ZM3.026,3V6.4H5.214a1.7,1.7,0,1,0,0-3.4Z" transform="translate(93.804 19.931)" fill="#E8490F"/>
                  <path id="Fill_30" data-name="Fill 30" d="M20.407,0,15.663,14.7H13.23L10.224,6.188,7.239,14.7H4.785L0,0H3.231L6.073,9.46,9.2,0h2.106l3.128,9.46L17.238,0Z" transform="translate(110.798 19.911)" fill="#E8490F"/>
                  <path id="Fill_31" data-name="Fill 31" d="M3.211,14.684H0L5.684,0H8.138l5.7,14.682H10.551l-.9-2.454H4.131l-.92,2.454Zm3.7-9.817-1.779,4.7H8.649Z" transform="translate(129.939 19.932)" fill="#E8490F"/>
                  <path id="Fill_32" data-name="Fill 32" d="M3.7,0,6.87,5.328,10.04,0h3.537L8.363,8.474v6.209H5.316V8.474L0,0Z" transform="translate(141.534 19.932)" fill="#E8490F"/>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PublicHeader',
  props: {}
}
</script>

<style scoped lang="scss">
.brand {
  svg {
    width: 150px;

    @media(min-width: 375px)
    {
      width: 180px;
    }

    @media(min-width: 768px)
    {
      width: 452px;
    }
  }
}
.waus-logo {
  svg {
    width: 90px;

    @media(min-width: 768px)
    {
      width: 180px;
    }
  }
}
</style>
