import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'
import Bus from '@/events/EventBus';

const winerySorts = ['wineries_name_asc', 'wineries_name_desc', 'wineries_state_asc', 'wineries_state_desc', 'wineries_region_asc', 'wineries_region_desc']
const productSorts = ['products_name_asc', 'products_name_desc', 'products_state_asc', 'products_state_desc', 'products_region_asc', 'products_region_desc']

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gotFavouriteProducts: false,
    favouriteProductsLoading: false,
    favouriteProducts: [],
    itinerariesLoading: false,
    itineraries: [],
    viewedProducts: [],
    ratedProducts: [],
    gotFavouriteWineries: false,
    favouriteWineriesLoading: false,
    favouriteWineries: [],
    viewedWineries: [],
    productSort: productSorts[Math.floor(Math.random() * productSorts.length)],
    winerySort: winerySorts[Math.floor(Math.random() * winerySorts.length)],
    query: '',
    searchView: 'wineries',
    eventView: 'upcoming'
  },
  mutations: {
    setFavouriteProducts (state, data) {
      Vue.set(state, 'favouriteProducts', data)
      Vue.set(state, 'gotFavouriteProducts', true)
    },
    setProductsLoading (state, data) {
      Vue.set(state, 'favouriteProductsLoading', data)
    },
    setViewedProducts (state, data) {
      Vue.set(state, 'viewedProducts', data)
    },
    setRatedProducts (state, data) {
      Vue.set(state, 'ratedProducts', data)
    },
    setItineraries (state, data) {
      Vue.set(state, 'itineraries', data)
    },
    setFavouriteWineries (state, data) {
      Vue.set(state, 'favouriteWineries', data)
      Vue.set(state, 'gotFavouriteWineries', true)
    },
    setWineriesLoading (state, data) {
      Vue.set(state, 'favouriteWineriesLoading', data)
    },
    setItinerariesLoading (state, data) {
      Vue.set(state, 'favouriteItinerariesLoading', data)
    },
    setViewedWineries (state, data) {
      Vue.set(state, 'viewedWineries', data)
    },
    setQuery (state, query) {
      console.log('setting query to ' + query)
      Vue.set(state, 'query', query)
    },
    setSearchView (state, view) {
      Vue.set(state, 'searchView', view)
    },
    setEventView (state, view) {
      Vue.set(state, 'eventView', view)
    },
    setProductSort (state, sort) {
      Vue.set(state, 'productSort', sort)
    },
    setWinerySort (state, sort) {
      Vue.set(state, 'winerySort', sort)
    }
  },
  actions: {
    updateProductSort ({ commit }, sort) {
      commit('setProductSort', sort)
    },
    updateWinerySort ({ commit }, sort) {
      commit('setWinerySort', sort)
    },
    getFavouriteProducts ({ commit }) {
      // if (!this.state.gotFavouriteProducts) {
      commit('setProductsLoading', true)
      api.getFavouriteProducts().then((res) => {
        commit('setFavouriteProducts', res.data.favouriteProductIds)
        Bus.$emit('favourite-products', res.data.favouriteProductIds)
      }).finally(() => {
        commit('setProductsLoading', false)
      })
      // }
    },
    addFavouriteProduct ({ commit }, productId) {
      if (!this.state.favouriteProductsLoading) {
        commit('setProductsLoading', true)
        api.addFavouriteProduct(productId).then((res) => {
          commit('setFavouriteProducts', res.data.favouriteProductIds)
          Bus.$emit('favourite-products', res.data.favouriteProductIds)
        }).finally(() => {
          commit('setProductsLoading', false)
        })
      }
    },
    removeFavouriteProduct ({ commit }, productId) {
      if (!this.state.favouriteProductsLoading) {
        commit('setProductsLoading', true)
        api.removeFavouriteProduct(productId).then((res) => {
          commit('setFavouriteProducts', res.data.favouriteProductIds)
          Bus.$emit('favourite-products', res.data.favouriteProductIds)
        }).finally(() => {
          commit('setProductsLoading', false)
        })
      }
    },
    getViewedProducts ({ commit }) {
      api.getViewedProducts().then((res) => {
        commit('setViewedProducts', res.data.viewedProductIds)
      })
    },
    addViewedProduct ({ commit }, productId) {
      api.addViewedProduct(productId).then((res) => {
        commit('setViewedProducts', res.data.viewedProductIds)
      })
    },
    getFavouriteWineries ({ commit }) {
      // if (!this.state.gotFavouriteWineries) {
      commit('setWineriesLoading', true)
      api.getFavouriteWineries().then((res) => {
        commit("setFavouriteWineries", res.data.favouriteWineryIds)
        Bus.$emit('favourite-wineries', res.data.favouriteWineryIds)
      }).finally(() => {
        commit('setWineriesLoading', false)
      })
      // }
    },
    addFavouriteWinery ({ commit }, wineryId) {
      if (!this.state.favouriteWineriesLoading) {
        commit('setWineriesLoading', true)
        api.addFavouriteWinery(wineryId).then((res) => {
          commit('setFavouriteWineries', res.data.favouriteWineryIds)
          Bus.$emit('favourite-wineries', res.data.favouriteWineryIds)
        }).finally(() => {
          commit('setWineriesLoading', false)
        })
      }
    },
    removeFavouriteWinery ({ commit }, wineryId) {
      if (!this.state.favouriteWineriesLoading) {
        commit('setWineriesLoading', true)
        api.removeFavouriteWinery(wineryId).then((res) => {
          commit('setFavouriteWineries', res.data.favouriteWineryIds)
          Bus.$emit('favourite-wineries', res.data.favouriteWineryIds)
        }).finally(() => {
          commit('setWineriesLoading', false)
        })
      }
    },
    getViewedWineries ({ commit }) {
      api.getViewedWineries().then((res) => {
        commit('setViewedWineries', res.data.viewedWineryIds)
      })
    },
    addViewedWinery ({ commit }, wineryId) {
      api.addViewedWinery(wineryId).then((res) => {
        commit('setViewedWineries', res.data.viewedWineryIds)
      })
    },
    getRatedProducts ({ commit }) {
      api.getRatedProducts().then((res) => {
        commit('setRatedProducts', res.data)
        Bus.$emit('rated-products', res.data.map((item) => {
          return item.productId
        }))
      })
    },
    getItineraries ({ commit }) {
      // if (!this.state.gotFavouriteProducts) {
      commit('setItinerariesLoading', true)
      api.getItineraries().then((res) => {
        commit('setItineraries', res.data.itineraryIds)
        console.log('got itineraries', res.data.itineraryIds)
        Bus.$emit('favourite-itineraries', res.data.itineraryIds)
      }).finally(() => {
        commit('setItinerariesLoading', false)
      })
      // }
    },
    addItineraries ({ commit }, itineraryId) {
      if (!this.state.itinerariesLoading) {
        commit('setItinerariesLoading', true)
        api.addItineraries(itineraryId).then((res) => {
          commit('setItineraries', res.data.itineraryIds)
          Bus.$emit('favourite-itineraries', res.data.itineraryIds)
        }).finally(() => {
          commit('setItinerariesLoading', false)
        })
      }
    },
    removeItineraries ({ commit }, itineraryId) {
      if (!this.state.itinerariesLoading) {
        commit('setItinerariesLoading', true)
        api.removeItineraries(itineraryId).then((res) => {
          commit('setItineraries', res.data.itineraryIds)
          Bus.$emit('favourite-itineraries', res.data.itineraryIds)
        }).finally(() => {
          commit('setItinerariesLoading', false)
        })
      }
    },
  },
  modules: {
  }
})
