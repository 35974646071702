import {getInstance} from "@/auth";

export default  {
    trackPage (name) {
        // const auth = getInstance()
        // identify if logged in
        // if(auth.isAuthenticated) {
        //     this.identify(auth.user)
        // }
        // segment
        window.analytics.page(name)
        console.log('track page', name)
    },
    identify () {
        const auth = getInstance()
        if(auth.isAuthenticated) {
            window.analytics.identify(auth.user.sub, {
                industry: auth.user['https://australianwine.com/industry_category'],
                country: auth.user['https://australianwine.com/country'] ? auth.user['https://australianwine.com/country'] : '',
                company: auth.user['https://australianwine.com/company_name'] ? auth.user['https://australianwine.com/company_name'] : ''
            });
            window.analytics.track('Login', {
                category: 'User',
                action: 'Login',
                userId: auth.user.sub,
                industry: auth.user['https://australianwine.com/industry_category'],
                company: auth.user['https://australianwine.com/company_name'] ? auth.user['https://australianwine.com/company_name'] : '',
                country: auth.user['https://australianwine.com/country'] ? auth.user['https://australianwine.com/country'] : ''
            })
            console.log('track identify', auth.user.sub, {
                industry: auth.user['https://australianwine.com/industry_category'],
                country: auth.user['https://australianwine.com/country'] ? auth.user['https://australianwine.com/country'] : '',
                company: auth.user['https://australianwine.com/company_name'] ? auth.user['https://australianwine.com/company_name'] : ''
            })
            console.log('track event', 'Login', {
                category: 'User',
                action: 'Login',
                userId: auth.user.sub,
                industry: auth.user['https://australianwine.com/industry_category'],
                company: auth.user['https://australianwine.com/company_name'] ? auth.user['https://australianwine.com/company_name'] : '',
                country: auth.user['https://australianwine.com/country'] ? auth.user['https://australianwine.com/country'] : ''
            })
        }
    },
    trackEvent (name, payload) {
        // const auth = getInstance()
        // identify if logged in
        // if(auth.isAuthenticated) {
        //     this.identify(auth.user)
        // }
        // segment
        window.analytics.track(name, payload)
        console.log('track event', name, payload)
    }
}
