import { getInstance } from "./index";

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        if (authService.isAuthenticated) {

            // console.log('----------------------------------------')
            // console.log('is authenticated')
            // console.log('to', to)
            // console.log('from', from)
            // console.log('----------------------------------------')


            if (!authService.user.email_verified) {
                return next({
                    path: '/verify-email'
                })
            }

            if (!authService.user['https://australianwine.com/accept_age']) {
                return next({
                    path: '/register-profile'
                })
            }

            // if (to.path === '/') {
            //     return next({path: '/home'})
            // }

            return next()
        } else {
            if (!to.meta.public) {
                authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
            } else {
                return next()
            }
        }
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};
