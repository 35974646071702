<template>
  <div class="sponsored-by">
    <h2 v-if="showHeading">Sponsored by —</h2>
    <div class="flex flex-row flex-wrap -mx-4" :class="{'mt-20': showHeading}">
      <div class="p-4 w-1/2 md:w-1/4" v-for="(sponsor, index) in sponsors" :key="`sponsor${index}`">
        <div class="bg-grey-100">
          <a target="_blank" :href="modular_content[sponsor].elements.url.value" class="image-container pb-1/1 relative block">
            <img :src="`${modular_content[sponsor].elements.logo.value[0].url}?w=400&auto=format`" class="absolute left-0 top-0 w-full h-full object-contain">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SponsorLinks',
  props: {
    sponsors: {
      type: Array,
      default () {
        return []
      }
    },
    modular_content: {
      type: Object,
      default () {
        return {}
      }
    },
    showHeading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 20px;
  font-weight: 600;

  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}
</style>
