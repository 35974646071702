<template>
  <div class="loading-spinner">
    <div class="lds"><div></div><div></div><div></div></div>
    <div v-if="message">{{message}}</div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    message: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.lds {
  display: block;
  position: relative;
  width: 40px;
  height: 80px;
  margin: 0 auto;
}
.lds div {
  display: inline-block;
  position: absolute;
  width: 8px;
  background: #E8490F;
  animation: lds 2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}
.lds div:nth-child(2) {
  left: 16px;
  animation-delay: -0.12s;
}
.lds div:nth-child(3) {
  right: 0;
  animation-delay: 0s;
}
@keyframes lds {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
</style>
