<template>
  <div class="register-popup" :class="{active: isVisible}">
    <button type="button" @click="hidePopup" class="close-button focus:outline-none">
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2754 11.875L21.6582 4.56055C22.0684 4.15039 22.0684 3.39844 21.6582 2.98828L19.9492 1.2793C19.5391 0.869141 18.7871 0.869141 18.377 1.2793L11.0625 8.66211L3.67969 1.2793C3.26953 0.869141 2.51758 0.869141 2.10742 1.2793L0.398438 2.98828C-0.0117188 3.39844 -0.0117188 4.15039 0.398438 4.56055L7.78125 11.875L0.398438 19.2578C-0.0117188 19.668 -0.0117188 20.4199 0.398438 20.8301L2.10742 22.5391C2.51758 22.9492 3.26953 22.9492 3.67969 22.5391L11.0625 15.1562L18.377 22.5391C18.7871 22.9492 19.5391 22.9492 19.9492 22.5391L21.6582 20.8301C22.0684 20.4199 22.0684 19.668 21.6582 19.2578L14.2754 11.875Z" fill="white"/>
      </svg>
    </button>
    <div class="inner">
      <div class="video" v-if="videoUrl">
        <video playsinline controls ref="video" poster="/img/register-preview.jpg">
          <source :src="videoUrl">
        </video>
      </div>
      <div class="cta">
        <button type="button" @click="register" class="btn-white btn-wide">Sign up</button>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/events/EventBus';
import Kontent from "@/kontent";
export default {
  name: 'RegisterPopup',
  data () {
    return {
      isVisible: false,
      videoUrl: null
    }
  },
  methods: {
    register () {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        appState: { targetUrl: this.$route.fullPath },
        screen_hint: 'signup'
      });
    },
    showPopup () {
      // this.playVideo()
      this.isVisible = true
    },
    hidePopup () {
      this.stopVideo()
      this.isVisible = false
    },
    playVideo () {
      this.$refs.video.play()
    },
    stopVideo () {
      this.$refs.video.pause()
      this.$refs.video.currentTime = 0
      this.$refs.video.load()
    }
  },
  mounted() {
    Kontent.getItemByType('landing_page').then((res) => {
      if (res.data.items[0].elements.register_video && res.data.items[0].elements.register_video.value.length) {
        this.videoUrl = res.data.items[0].elements.register_video.value[0].url
      }
    })
    Bus.$on('show-register-popup', () => {
      this.showPopup()
    })
  },
  beforeDestroy() {
    Bus.$off('show-register-popup')
  }
}
</script>

<style lang="scss" scoped>
.register-popup {
  position: fixed;
  background-color: rgba(#000, 0.8);
  box: horizontal middle center;
  padding: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.cta {
  margin-top: 30px;
  text-align: center;
  padding-bottom: 10px;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.inner {
  background-color: #232323;
  padding: 20px;
}

video {
  width: 100%;
  max-width: 940px;
  height: auto;
}
</style>
