import axios from 'axios'
import {getInstance} from "@/auth";

export default {
    getProductNotes (productId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}notes/products/${productId}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addProductNotes (productId, notes) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.put(`${process.env.VUE_APP_API}notes/products/${productId}`, {
                notes
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getWineryNotes (wineryId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}notes/wineries/${wineryId}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addWineryNotes (wineryId, notes) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.put(`${process.env.VUE_APP_API}notes/wineries/${wineryId}`, {notes}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getFavouriteWineries () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}favourites/wineries/`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getFavouriteProducts () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}favourites/products/`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addFavouriteProduct (productId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}favourites/products/${productId}`, {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    removeFavouriteProduct (productId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.delete(`${process.env.VUE_APP_API}favourites/products/${productId}`,  {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addFavouriteWinery (wineryId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}favourites/wineries/${wineryId}`, {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    removeFavouriteWinery (wineryId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.delete(`${process.env.VUE_APP_API}favourites/wineries/${wineryId}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getProductRating (productId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}ratings/products/${productId}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    setProductRating (productId, rating) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.put(`${process.env.VUE_APP_API}ratings/products/${productId}`, {rating}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getRatedProducts () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}ratings/products`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getViewedProducts () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}viewed/products`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addViewedProduct (productId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}viewed/products`, {productId}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getViewedWineries () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}viewed/wineries`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addViewedWinery (wineryId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}viewed/wineries`, {wineryId}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    sendVerificationEmail () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}auth/resend-verification-email`, null, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    getItineraries () {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.get(`${process.env.VUE_APP_API}itineraries`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    updateProfile (profile) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}auth0/update-profile`, { profile }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    addItineraries (itineraryId) {
        console.log('addItineraries', itineraryId)
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.post(`${process.env.VUE_APP_API}itineraries/${itineraryId}`, {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    removeItineraries (itineraryId) {
        const auth = getInstance()
        return auth.getTokenSilently().then((token) => {
            return axios.delete(`${process.env.VUE_APP_API}itineraries/${itineraryId}`,  {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })
    },
    queryItinerary (itineraryId) {
        return axios.post(`${process.env.VUE_APP_ALPACA_GRAPHQL}?accessToken=${process.env.VUE_APP_ALPACA_KEY}`,  {
            query: `query UserItinerary { 
  itinerary(id:"itinerary/${itineraryId}") {
    created
    title
    children(first:1, type:ItineraryLocation) {
    totalCount
      nodes {
        ... on ItineraryLocation {
          place {
            name
            preferredMedia {
              resource {
                ... on MediaImage {
                  source(bestFit:[400,300]) {
                    url
                  }
                }
                altText
              }
            }
          }  
        }
      }
    }
  }
}`
        })
    }
}
