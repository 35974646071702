<template>
  <div class="map-popup" :class="{active: isVisible}">
      <button type="button" @click="hideThis" class="close-button focus:outline-none">
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.2754 11.875L21.6582 4.56055C22.0684 4.15039 22.0684 3.39844 21.6582 2.98828L19.9492 1.2793C19.5391 0.869141 18.7871 0.869141 18.377 1.2793L11.0625 8.66211L3.67969 1.2793C3.26953 0.869141 2.51758 0.869141 2.10742 1.2793L0.398438 2.98828C-0.0117188 3.39844 -0.0117188 4.15039 0.398438 4.56055L7.78125 11.875L0.398438 19.2578C-0.0117188 19.668 -0.0117188 20.4199 0.398438 20.8301L2.10742 22.5391C2.51758 22.9492 3.26953 22.9492 3.67969 22.5391L11.0625 15.1562L18.377 22.5391C18.7871 22.9492 19.5391 22.9492 19.9492 22.5391L21.6582 20.8301C22.0684 20.4199 22.0684 19.668 21.6582 19.2578L14.2754 11.875Z" fill="white"/>
          </svg>
      </button>
    <div class="outer-container popup-container">
        <div class="inner-container">
                <GmapMap
                        :center="mapPosition"
                        :zoom="6"
                        map-type-id="terrain"
                        style="width: 100%; height: 500px"
                        :options="{disableDefaultUI: true, styles: mapStyles}"
                >
                    <GmapMarker
                            key="marker"
                            :position="mapPosition"
                            icon="/img/marker.png"
                    />
                </GmapMap>
        </div>
    </div>
  </div>
</template>

<script>

// AIzaSyCMQpgg3MRYuLnR_4A6BQsFHof3OSWpxfc
import Bus from '@/events/EventBus';
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCMQpgg3MRYuLnR_4A6BQsFHof3OSWpxfc',
    },
    installComponents: true,
})
export default {
  name: 'MapPopup',
  data () {
    return {
      isVisible: false,
        mapPosition: {
            lat: -33.344666,
            lng: 151.503308
        },
        mapStyles: [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c8d7d4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            }
        ]
    }
  },
  methods: {
    showThis() {
      this.isVisible = true
    },
    hideThis () {
      this.isVisible = false
    }
  },
  mounted() {
    Bus.$on('show-winery-map', (winery) => {
        this.mapPosition = {
            ...this.mapPosition,
            lat: parseFloat(winery.elements.latitude.value),
            lng: parseFloat(winery.elements.longitude.value)
        }
        this.showThis()
    })
  },
  beforeDestroy() {
    Bus.$off('show-winery-map')
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  max-width: 820px;
  text-align: center;
}
.map-popup {
  position: fixed;
  background-color: rgba(#000, 0.8);
  box: horizontal middle center;
  padding: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.video-button {
  margin-top: 30px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
  @media(min-width: 768px)
  {
    font-size: 24px;
    margin-top: 40px;
  }

  button {
    font-size: 14px;
    text-transform: none;
    font-weight: bold;
  }
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.preview {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: -60px;
  margin-top: 30px;
  max-width: 520px;
  z-index: 1;

  img {
    display: block;
    width: 100%;
  }

  @media(min-width: 1024px)
  {
    position: absolute;
    right: 30px;
    margin: 0;
    padding: 0;
    width: 400px;
    bottom: -30px;
  }

  @media(min-width: 1200px)
  {
    width: 600px;
    bottom: -60px;
  }
}

.box-buttons {
  margin-top: 20px;
  max-width: 600px;
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;

  button {
    width: 100%;
    margin-top: 10px;

    @media(min-width: 660px)
    {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  @media(min-width: 660px)
  {
    box: horizontal middle center;
  }
}
.box-heading {
  font-size: 29px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 20px;
  position: relative;
  z-index: 2;
  @media(min-width: 768px)
  {
    font-size: 42px;
  }
}
.box {
  background-color: #232323;
  margin-left: -10px;
  margin-right: -10px;
  padding: 30px;
  position: relative;

  @media(min-width: 1024px)
  {
    padding-bottom: 60px;
  }

  @media(min-width: 768px)
  {
    margin-bottom: 70px;
  }

  @media(min-width: 1200px)
  {
    margin-bottom: 100px;
  }

  @media(min-width: 1300px)
  {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.heading {
  box: horizontal middle left;
  font-size: 18px;
  font-weight: bold;
  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}

.image {
  margin-top: 4rem;

  img {
    display: block;
    width: 100%;
  }

  .large {
    display: none;
    @media(min-width: 768px)
    {
      display: block;
    }
  }

  .small {
    @media(min-width: 768px)
    {
      display: none;
    }
  }
}

.lock {
  margin-right: 10px;
}
</style>
