<template>
  <footer class="py-12 md:py-24" :class="[{'bg-grey-100': mode === 'grey'}, {'bg-black': mode === 'black'}, {'mt-24': margin}]">
    <div class="outer-container">
      <div class="inner-container">

        <div class="country mb-8 md:mb-24 pb-12 border-b border-white border-opacity-10 md:flex md:items-center md:justify-center">
          <svg class="flex-shrink-0 shrink-0" width="60" height="58" viewBox="0 0 60 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.102 57.9989C49.1723 57.9433 49.584 56.3035 50.4897 55.6364C51.1484 55.0527 50.9288 54.2189 51.0111 53.4407C51.1209 52.1344 50.3525 52.051 49.5017 52.1066C48.6509 52.2733 47.9099 52.7736 47.1963 51.9676C46.0162 50.8559 45.0831 52.1066 45.4398 53.2461C45.6594 53.9688 45.7966 55.1917 45.8241 55.4697C45.879 55.9977 47.0591 58.0545 48.102 57.9989Z" fill="white"/>
            <path d="M40.1155 10.1377C38.4689 9.16494 35.0931 7.85862 34.4894 6.1354C34.6266 5.30158 37.673 1.82734 34.901 1.49381C32.5408 2.07749 30.6745 0.298673 28.3692 0.326467C23.8408 -0.0626478 26.0912 2.38322 24.0878 5.05144C22.743 7.21937 22.0294 2.57778 19.1202 4.55115C18.3243 5.246 17.4461 5.80187 16.9247 6.80246C16.266 7.27495 15.3054 7.80304 14.537 8.16436C12.6707 8.74803 14.2076 10.8604 12.2865 12.5002C8.82844 14.1679 4.7666 14.2234 1.44578 16.2524C0.0460926 17.114 -0.200911 20.5605 0.347986 22.0335C0.677324 23.0897 -0.530249 23.8957 0.293096 24.9241C1.089 25.8413 1.00666 27.092 1.44578 28.1482C2.51613 29.5657 1.47322 31.4835 2.6808 32.8454C4.19026 35.0133 1.19878 36.4308 2.3789 37.9039C3.28458 38.7099 4.02559 39.8495 5.34294 39.7383C7.92276 40.4331 9.18522 37.4314 11.5729 37.5981C16.5953 38.5431 15.8269 35.0689 20.1632 35.0411C22.4136 34.6242 24.8288 33.0399 26.9969 34.4574C29.4121 35.0967 30.0707 34.9577 31.4155 37.348C32.5957 41.9618 35.7518 39.4881 38.0297 42.6844C38.7159 44.2687 39.0178 46.0475 40.9663 46.6034C45.5771 49.1326 44.6165 46.2699 46.4279 47.6595C47.2238 48.3266 48.2118 49.1048 49.1175 48.1598C51.5326 45.1025 53.1518 47.5762 53.8105 44.9635C56.0335 36.0695 64.7336 29.2878 56.5824 20.7272C55.9787 19.5877 56.5276 18.7539 55.0181 18.1702C54.6613 18.0034 54.4417 17.8089 54.332 17.5031C53.7282 15.8355 52.6029 14.6126 51.2033 13.6398C49.5017 12.6948 49.8585 10.7492 48.9253 9.30391C48.2392 8.27553 48.7881 6.52452 47.3884 6.05202C45.632 5.60732 46.1534 -0.396175 43.7932 0.0207339C43.2991 0.13191 43.0796 0.521025 43.0796 1.0769C43.0247 2.49439 41.872 10.4713 40.1155 10.1377Z" fill="white"/>
          </svg>
          <div class="text mt-6 md:mt-0 md:ml-12">
            In the spirit of reconciliation, Wine Australia acknowledges the Traditional Custodians of country throughout Australia and their connections to land, sea and community. We pay our respect to their Elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.
          </div>
        </div>

        <div class="row space-y-8 sm:space-y-0 sm:flex sm:flex-row">
          <div class="logo-col sm:w-1/2 md:w-4/10">
            <div class="logo">
              <svg xmlns="http://www.w3.org/2000/svg" width="124" height="24" viewBox="0 0 124 24">
                <g id="Lockup_AustralianWineMOW" transform="translate(0)">
                  <rect id="Lockup_AustralianWineMOW_White_background" data-name="Lockup_AustralianWineMOW (White) background" width="124" height="24" fill="none"/>
                  <path id="Combined_Shape" data-name="Combined Shape" d="M2.2,10.069H0L3.894,0H5.575L9.484,10.068H7.228L6.613,8.386H2.83L2.2,10.069ZM4.735,3.337,3.516,6.559h2.41Z" transform="translate(0 0.551)" fill="#fff"/>
                  <path id="Fill_2" data-name="Fill 2" d="M0,6.17V0H2.073V6.17A1.848,1.848,0,0,0,3.894,8.127,1.837,1.837,0,0,0,5.7,6.17V0H7.774V6.17A3.848,3.848,0,0,1,3.894,10.2,3.851,3.851,0,0,1,0,6.17" transform="translate(10.017 0.551)" fill="#fff"/>
                  <path id="Fill_4" data-name="Fill 4" d="M6.318,3.222a3.721,3.721,0,0,0-2.48-1.165c-.7,0-1.177.331-1.177.82,0,1.712,5.015.676,5.015,4.344a3.3,3.3,0,0,1-3.614,3.136A5.507,5.507,0,0,1,0,8.443L1.331,6.89a3.67,3.67,0,0,0,2.732,1.4c.882,0,1.485-.4,1.485-1.05,0-1.7-4.973-.576-4.973-4.2A3.156,3.156,0,0,1,4.006,0,5.04,5.04,0,0,1,7.522,1.539Z" transform="translate(18.816 0.407)" fill="#fff"/>
                  <path id="Fill_7" data-name="Fill 7" d="M7.8,2.042H4.931v8.026H2.858V2.042H0V0H7.8Z" transform="translate(27.207 0.551)" fill="#fff"/>
                  <path id="Fill_9" data-name="Fill 9" d="M2.073,10.069H0V0H3.782A3.115,3.115,0,0,1,5.95.906a3.078,3.078,0,0,1,.928,2.2A2.976,2.976,0,0,1,5.309,5.9l2.564,4.17H5.351L3.138,6.228H2.073v3.84Zm0-8.012v2.33h1.5a1.166,1.166,0,1,0,0-2.33Z" transform="translate(36.328 0.55)" fill="#fff"/>
                  <path id="Fill_11" data-name="Fill 11" d="M2.2,10.069H0L3.894,0H5.575L9.483,10.068H7.228L6.611,8.386H2.83L2.2,10.069ZM4.735,3.337,3.516,6.559h2.41Z" transform="translate(44.804 0.551)" fill="#fff"/>
                  <path id="Fill_13" data-name="Fill 13" d="M2.073,0V8.026h4.5v2.043H0V0Z" transform="translate(55.395 0.551)" fill="#fff"/>
                  <path id="Fill_15" data-name="Fill 15" d="M0,10.069H2.073V0H0Z" transform="translate(63.339 0.551)" fill="#fff"/>
                  <path id="Fill_17" data-name="Fill 17" d="M2.2,10.069H0L3.895,0H5.575L9.484,10.068H7.228L6.612,8.386H2.83L2.2,10.069ZM4.735,3.337,3.516,6.559H5.925L4.735,3.337Z" transform="translate(66.519 0.551)" fill="#fff"/>
                  <path id="Fill_18" data-name="Fill 18" d="M1.765,0,5.757,5.869V0H7.831V10.069H6.094L2.073,4.2v5.869H0V0Z" transform="translate(77.111 0.551)" fill="#fff"/>
                  <path id="Fill_19" data-name="Fill 19" d="M13.98,0l-3.25,10.083H9.063L7,4.243l-2.045,5.84H3.278L0,0H2.213L4.161,6.487,6.3,0H7.747L9.89,6.487,11.809,0Z" transform="translate(89.215 0.536)" fill="#fff"/>
                  <path id="Fill_20" data-name="Fill 20" d="M0,10.069H2.073V0H0Z" transform="translate(104.346 0.551)" fill="#fff"/>
                  <path id="Fill_21" data-name="Fill 21" d="M1.765,0,5.757,5.869V0H7.831V10.069H6.094L2.073,4.2v5.869H0V0Z" transform="translate(108.185 0.551)" fill="#fff"/>
                  <path id="Fill_22" data-name="Fill 22" d="M6.01,0V2.042H2.073V3.855h3.5V5.9h-3.5V8.026H6.01v2.043H0V0Z" transform="translate(117.781 0.551)" fill="#fff"/>
                  <path id="Fill_23" data-name="Fill 23" d="M0,0H2.522L5.435,6.933,8.489,0H10.6V10.069H8.531V4.157L6.024,10.069h-1.5L2.073,4.157v5.912H0Z" transform="translate(0.658 13.667)" fill="#E8490F"/>
                  <path id="Fill_24" data-name="Fill 24" d="M2.2,10.069H0L3.894,0H5.575L9.483,10.068H7.228L6.611,8.386H2.83L2.2,10.069ZM4.735,3.337,3.516,6.559h2.41Z" transform="translate(12.371 13.667)" fill="#E8490F"/>
                  <path id="Fill_25" data-name="Fill 25" d="M3.362,10.069H0V0H3.362A4.963,4.963,0,0,1,6.91,1.422,4.994,4.994,0,0,1,8.335,4.991,5.127,5.127,0,0,1,6.91,8.625,4.921,4.921,0,0,1,3.362,10.069ZM2.073,2.042V8.026H3.5a2.783,2.783,0,0,0,2.69-3.035A2.735,2.735,0,0,0,3.5,2.042Z" transform="translate(22.962 13.667)" fill="#E8490F"/>
                  <path id="Fill_26" data-name="Fill 26" d="M6.01,0V2.042H2.073V3.855h3.5V5.9h-3.5V8.026H6.01v2.042H0V0Z" transform="translate(32.783 13.667)" fill="#E8490F"/>
                  <path id="Fill_27" data-name="Fill 27" d="M5,10.356A4.98,4.98,0,0,1,0,5.178,4.98,4.98,0,0,1,5,0a4.986,4.986,0,0,1,5.015,5.178A4.987,4.987,0,0,1,5,10.356ZM5,2.071A2.93,2.93,0,0,0,2.129,5.178,2.931,2.931,0,0,0,5,8.285,2.93,2.93,0,0,0,7.873,5.178,2.93,2.93,0,0,0,5,2.071Z" transform="translate(43.22 13.524)" fill="#E8490F"/>
                  <path id="Fill_28" data-name="Fill 28" d="M0,6.17V0H2.073V6.17A1.848,1.848,0,0,0,3.894,8.127,1.837,1.837,0,0,0,5.7,6.17V0H7.774V6.17A3.848,3.848,0,0,1,3.894,10.2,3.851,3.851,0,0,1,0,6.17" transform="translate(54.723 13.668)" fill="#E8490F"/>
                  <path id="Fill_29" data-name="Fill 29" d="M2.073,10.069H0V0H3.782A3.115,3.115,0,0,1,5.95.906a3.078,3.078,0,0,1,.928,2.2A2.976,2.976,0,0,1,5.309,5.9l2.563,4.17H5.351L3.138,6.228H2.073v3.84Zm0-8.012v2.33h1.5a1.166,1.166,0,1,0,0-2.33Z" transform="translate(64.264 13.667)" fill="#E8490F"/>
                  <path id="Fill_30" data-name="Fill 30" d="M13.98,0l-3.25,10.083H9.063L7,4.243l-2.045,5.84H3.278L0,0H2.213L4.161,6.487,6.3,0H7.747L9.89,6.487,11.809,0Z" transform="translate(75.906 13.653)" fill="#E8490F"/>
                  <path id="Fill_31" data-name="Fill 31" d="M2.2,10.069H0L3.894,0H5.575L9.483,10.068H7.228L6.611,8.386H2.83L2.2,10.069ZM4.735,3.337,3.516,6.559h2.41Z" transform="translate(89.019 13.667)" fill="#E8490F"/>
                  <path id="Fill_32" data-name="Fill 32" d="M2.535,0,4.706,3.653,6.878,0H9.3L5.729,5.811v4.258H3.642V5.811L0,0Z" transform="translate(96.963 13.667)" fill="#E8490F"/>
                </g>
              </svg>

            </div>

            <div class="share mt-12">
              <div class="text-grey-400 text-10 uppercase">Follow us</div>
              <social-icons youtube="https://www.youtube.com/channel/UCcvnoaYz9Ju_vliN1I4LODg" twitter="https://twitter.com/wine_australia" linkedin="https://www.linkedin.com/company/wine-australia/" class="mt-4" />
            </div>
          </div>
          <div class="nav-col sm:w-1/2 md:w-6/10 lg:pr-2/10">
            <div class="row md:flex md:flex-row">
              <div class="col md:flex-1">
                <nav>
                  <ul class="space-y-8">
                    <li><router-link to="/privacy" class="text-white hover:text-primary-500 transition-colors">Privacy Policy</router-link></li>
                    <li><router-link to="/terms" class="text-white hover:text-primary-500 transition-colors">Terms &amp; Conditions</router-link></li>
                    <li><a href="https://sustainablewinegrowing.com.au/" target="_blank" class="text-white hover:text-primary-500 transition-colors">Sustainable Winegrowing Australia</a></li>
                  </ul>
                </nav>
              </div>
              <div class="col mt-8 md:-mt-0 md:flex-1">
                <nav>
                  <ul class="space-y-8">
                    <li><a href="https://app.smartsheet.com/b/form/0f566bc5273f45248c8ed20b7808dd3e" target="_blank" class="text-white hover:text-primary-500 transition-colors">Contact Us</a></li>
                    <li><a href="https://www.australianwine.com/en-AU" target="_blank" class="text-white hover:text-primary-500 transition-colors">Visit Australian Wine</a></li>
                    <li><a href="https://www.wineaustralia.com/education" target="_blank" class="text-white hover:text-primary-500 transition-colors">Australian Wine Discovered </a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>


        <div class="copyright mt-12 md:mt-24 pt-12 border-t border-white border-opacity-10 text-grey-400">
          <div class="row space-y-8 lg:flex lg:flex-row lg:space-y-0">
            <div class="copyright-col lg:w-4/10">
              &copy; Wine Australia. ABN: 89 636 749 924
            </div>
            <div class="links-col lg:w-6/10">
              <ul class="md:flex md:flex-row md:flex-wrap md:space-x-4">
                <li><router-link to="/copyright" class="text-grey-400 hover:text-grey-500 transition-colors">Copyright</router-link></li>
                <li><router-link to="/disclaimer" class="text-grey-400 hover:text-grey-500 transition-colors">Disclaimer</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialIcons from "@/components/SocialIcons";
export default {
  name: 'AppFooter',
  components: {SocialIcons},
  props: {
    mode: {
      type: String,
      default: 'grey'
    },
    margin: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
