import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '@/auth/AuthGuard'
import { emailGuard } from '@/auth/EmailGuard'
import { publicGuard } from '@/auth/PublicGuard'

import VueGtm from '@gtm-support/vue2-gtm';

// import Home from '@/views/Home.vue'
// import Profile from '@/views/Profile.vue'
import Members from '@/views/Members.vue'
// import Discover from '@/views/Discover.vue'
// import State from '@/views/State.vue'
// import Region from '@/views/Region.vue'
// import Collection from '@/views/Collection.vue'
// import Expo from '@/views/Expo.vue'
// import Conversations from '@/views/Conversations.vue'
// import Winery from '@/views/Winery.vue'
// import Product from '@/views/Product.vue'
// import NewLanding from '@/views/NewLanding.vue'
// import Register from '@/views/Register.vue'
// import RegisterProfile from '@/views/RegisterProfile.vue'
// import Login from '@/views/Login.vue'
// import ForgotPassword from '@/views/ForgotPassword.vue'
// import AuthCallback from '@/views/AuthCallback.vue'
// import RegistrationReceived from "@/views/RegistrationReceived";
// import VerifyEmail from "@/views/VerifyEmail";
// import PageNotFound from "@/views/PageNotFound";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Members,
    beforeEnter: publicGuard,
    meta: {
      public: false,
      footerMode: 'black',
      transparentHeader: false
    }
  },
  {
    path: '/register-profile',
    name: 'RegisterProfile',
    component: () => import('@/views/RegisterProfile.vue'),
    beforeEnter: emailGuard,
    meta: {
      public: false,
      footerMargin: false
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue'),
    meta: {
      public: true,
      footerMargin: false,
      transparentHeader: false,
      both: true
    }
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: () => import('@/views/Copyright.vue'),
    meta: {
      public: true,
      footerMargin: false,
      transparentHeader: false,
      both: true
    }
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import('@/views/Disclaimer.vue'),
    meta: {
      public: true,
      footerMargin: false,
      transparentHeader: false,
      both: true
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
    meta: {
      public: true,
      footerMargin: false,
      transparentHeader: false,
      both: true
    }
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/NewLanding.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    beforeEnter: authGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/home',
    name: 'members',
    component: Members,
    beforeEnter: publicGuard,
    meta: {
      public: false,
      footerMode: 'black',
      transparentHeader: false
    }
  },
  {
    path: '/experience',
    name: 'experience',
    component: () => import('@/views/Experience.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/experience/:event',
    name: 'experience-event',
    component: () => import('@/views/ExperienceEvent.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/experience/:event/exhibitors',
    name: 'experience-event',
    component: () => import('@/views/ExperienceEventExhibitors.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/experience/:event/day:day',
    name: 'experience-event-day',
    component: () => import('@/views/ExperienceEventDay.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  // {
  //   path: '/explore/map',
  //   name: 'itinerary',
  //   component: () => import('@/views/Explore.vue'),
  //   beforeEnter: authGuard,
  //   meta: {
  //     public: false
  //   }
  // },
  {
    path: '/discover',
    name: 'discover',
    component: () => import('@/views/Discover.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/discover/spotlight',
    name: 'discoverSpotlight',
    component: () => import('@/views/Discover.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/discover/collections',
    name: 'collections',
    component: () => import('@/views/Collections.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/discover/:state',
    name: 'state',
    component: () => import('@/views/State.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/discover/collections/:collection',
    name: 'collection',
    component: () => import('@/views/Collection.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/discover/:state/:region',
    name: 'region',
    component: () => import('@/views/Region.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/discover/:state/associated/:region',
    name: 'associatedregion',
    component: () => import('@/views/AssociatedRegion.vue'),
    beforeEnter: publicGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/expo',
    name: 'expo',
    component: () => import('@/views/Expo.vue'),
    // beforeEnter: authGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/expo/wineries/:winery',
    name: 'winery',
    component: () => import('@/views/Winery.vue'),
    // beforeEnter: authGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/expo/products/:product',
    name: 'product',
    component: () => import('@/views/Product.vue'),
    // beforeEnter: authGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/expo/products/regional/:product',
    name: 'regionalProduct',
    component: () => import('@/views/RegionalProduct.vue'),
    // beforeEnter: authGuard,
    meta: {
      public: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      public: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/views/Redirect.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/404',
    name: 'page-not-found',
    component: () => import('@/views/PageNotFound.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/:slug',
    name: 'landing-page',
    component: () => import('@/views/LandingPage.vue'),
    meta: {
      public: false
    }
  },
  {
    path: '/exhibitor/:slug',
    name: 'exhibitor',
    component: () => import('@/views/Exhibitor.vue'),
    meta: {
      public: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to) {
    if (!to.hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  routes
})

Vue.use(VueGtm, {
  id: 'GTM-TMKNQRQ',
  defer: false,
  compatibility: false,
  enabled: process.env.VUE_APP_ENABLE_GTM !== 'false',
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});

router.beforeEach((to, from, next) => {
  if (to.query.utm_medium) {
    switch (to.query.utm_medium) {
      case 'bigmarker':
        Vue.$cookies.set('signup-source', 'BigMarker',null,'/');
        break
      case 'email':
        Vue.$cookies.set('signup-source', 'EDM',null,'/');
        break
      case 'organic':
        Vue.$cookies.set('signup-source', 'Organic search',null,'/');
        break
      case 'ppc':
        Vue.$cookies.set('signup-source', 'Paid search',null,'/');
        break
    }
  }
  if (to.query.utm_source) {
    switch (to.query.utm_source) {
      case 'twitter':
        Vue.$cookies.set('signup-source', 'Twitter',null,'/');
        break
      case 'linkedin':
        Vue.$cookies.set('signup-source', 'LinkedIn',null,'/');
        break
    }
  }
  next()
})

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    let reg = new RegExp('^Redirected when going from "[a-z_.\\/]+" to "[a-z_.\\/]+" via a navigation guard.$');
    if (reg.test(err.message)) {
      // If pushing interrupted because of redirection from navigation guard - ignore it.
      return Promise.resolve(false);
    }
    // Otherwise throw error
    return Promise.reject(err);
  });
};

export default router
