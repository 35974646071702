import axios from 'axios'
import dayjs from 'dayjs'

let headers = {}
if (process.env.VUE_APP_PREVIEW === 'true') {
    headers = { Authorization: 'Bearer ' + process.env.VUE_APP_KONTENT_PREVIEW_API }
}
let deliveryUrl = `https://deliver.kontent.ai/${process.env.VUE_APP_KONTENT_PROJECT_ID}`
if (process.env.VUE_APP_PREVIEW === 'true') {
    deliveryUrl = `https://preview-deliver.kontent.ai/${process.env.VUE_APP_KONTENT_PROJECT_ID}`
}

export default {
    getItemByType (type) {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=${type}&order=system.last_modified[desc]&limit=1`, {headers: headers})
    },
    getItem (type, slug, depth) {
        return axios.get(`${deliveryUrl}/items?depth=${depth}&system.type=${type}&elements.slug=${slug}&order=system.last_modified[desc]&limit=1`, {headers: headers})
    },
    getWineryByConnectId (connectId) {
        return axios.get(`${deliveryUrl}/items?system.type=winery&elements.winery_id=${connectId}&order=system.last_modified[desc]&limit=1`, {headers: headers})
    },
    getProductsByWinery (winery) {
        return axios.get(`${deliveryUrl}/items?system.type=product&elements.winery[contains]=${winery}&order=elements.listing_order&limit=30`, {headers: headers})
    },
    getProductsByState (state) {
        if (state.toLowerCase() === 'new south wales') {
            state = 'NSW%20%26%20ACT'
        }
        return axios.get(`${deliveryUrl}/items?system.type=product&elements.state=${state}&limit=10`, {headers: headers})
    },
    getWineriesByState (state) {
        if (state.toLowerCase() === 'new south wales') {
            state = 'NSW%20%26%20ACT'
        }
        return axios.get(`${deliveryUrl}/items?system.type=winery&elements.state=${state}&limit=10`, {headers: headers})
    },
    getFavouriteProducts (ids) {
        return axios.get(`${deliveryUrl}/items?system.type=product&elements.product_id[in]=${ids}`, {headers: headers})
    },
    getFavouriteWineries (ids) {
        return axios.get(`${deliveryUrl}/items?system.type=winery&elements.winery_id[in]=${ids}`, {headers: headers})
    },
    getCollections () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=collection&limit=6&order=elements.order`, {headers: headers})
    },
    getCollectionsCount () {
        return axios.get(`${deliveryUrl}/items?depth=0&system.type=collection`, {headers: headers})
    },
    getAllCollections () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=collection&order=elements.order`, {headers: headers})
    },
    getUpcomingEvents () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=event&elements.end_date[gt]=${dayjs().format('YYYY-MM-DD')}&elements.event_type[contains]=standard`, {headers: headers})
    },
    getPreviousEvents () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=event&elements.end_date[lte]=${dayjs().format('YYYY-MM-DD')}&elements.event_type[contains]=standard`, {headers: headers})
    },
    getOnDemandEvents () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=event&elements.event_type[contains]=on_demand`, {headers: headers})
    },
    getIndustryTypes () {
        return axios.get(`${deliveryUrl}/taxonomies/industry_categories`, {headers: headers})
    },
    getAssociatedRegions (state) {
        switch (state) {
            case 'new-south-wales':
                state = 'New South Wales'
                break
            case 'queensland':
                state = 'Queensland'
                break
            case 'south-australia':
                state = 'South Australia'
                break
            case 'western-australia':
                state = 'Western Australia'
                break
            case 'tasmania':
                state = 'Tasmania'
                break
            case 'victoria':
                state = 'Victoria'
                break
        }
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=associated_region&elements.state=${state}`, {headers: headers})
    },
    getAllAssociatedRegions () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=associated_region`, {headers: headers})
    },
    getAssociatedRegion (slug) {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=associated_region&elements.slug=${slug}`, {headers: headers})
    },
    getAssociatedRegionForRegion (region) {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=associated_region&elements.state__zone__region=${region}`, {headers: headers})
    },
    getAssociatedCollections (region) {
        // get regional products with this region id
        return axios.get(`${deliveryUrl}/items?depth=1&system.type=regional_product&elements.region[contains]=${region}`, {headers: headers}).then((res) => {
            // get collections with this product
            let pArray = []
            for (let i = 0; i < res.data.items.length; i++) {
                const item = res.data.items[i];
                pArray.push(item.system.codename)
            }
            return axios.get(`${deliveryUrl}/items?depth=1&system.type=collection&elements.products[any]=${pArray.join(',')}`, {headers: headers})
        })
    },
    getFeaturedCollections (region) {
        return axios.get(`${deliveryUrl}/items?system.type=collection&elements.featured_in_region[contains]=${region}&order=elements.order`, {headers: headers})
    },
    getAssociatedProducts (region) {
        return axios.get(`${deliveryUrl}/items?system.type=regional_product&elements.region[contains]=${region}&order=elements.listing_order`, {headers: headers})
    },
    getProductCollections (product) {
        return axios.get(`${deliveryUrl}/items?system.type=collection&elements.products[contains]=${product}`, {headers: headers})
    },
    getWineryCollections (products) {
        return axios.get(`${deliveryUrl}/items?system.type=collection&elements.products[any]=${products}`, {headers: headers})
    },
    getEventsForAssociatedRegion (region) {
        return axios.get(`${deliveryUrl}/items?system.type=bigmarker_event&elements.associated_region[contains]=${region}`, {headers: headers})
    },
    getExperienceEvents () {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=experience_event&order=elements.start_date[desc]`, {headers: headers})
    },
    getExperienceEvent (slug) {
        return axios.get(`${deliveryUrl}/items?depth=5&system.type=experience_event&order=system.last_modified[desc]&limit=1&elements.slug=${slug}`, {headers: headers})
    }
}
