import { getInstance } from "./index";

export const publicGuard = (to, from, next) => {
    const authService = getInstance();

    const fn = () => {
        if (authService.isAuthenticated) {

            if (!authService.user.email_verified) {
                return next({
                    path: '/verify-email'
                })
            }

            if (!authService.user['https://australianwine.com/accept_age']) {
                return next({
                    path: '/register-profile'
                })
            }

            return next()
        } else {
            return next()
        }
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!authService.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};
