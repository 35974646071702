<template>
  <div class="locked-content" :class="{active: isVisible}">
    <button type="button" @click="hideThis" class="close-button focus:outline-none">
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2754 11.875L21.6582 4.56055C22.0684 4.15039 22.0684 3.39844 21.6582 2.98828L19.9492 1.2793C19.5391 0.869141 18.7871 0.869141 18.377 1.2793L11.0625 8.66211L3.67969 1.2793C3.26953 0.869141 2.51758 0.869141 2.10742 1.2793L0.398438 2.98828C-0.0117188 3.39844 -0.0117188 4.15039 0.398438 4.56055L7.78125 11.875L0.398438 19.2578C-0.0117188 19.668 -0.0117188 20.4199 0.398438 20.8301L2.10742 22.5391C2.51758 22.9492 3.26953 22.9492 3.67969 22.5391L11.0625 15.1562L18.377 22.5391C18.7871 22.9492 19.5391 22.9492 19.9492 22.5391L21.6582 20.8301C22.0684 20.4199 22.0684 19.668 21.6582 19.2578L14.2754 11.875Z" fill="white"/>
      </svg>
    </button>
    <div class="outer-container popup-container">
      <div class="inner-container">
        <div class="box">
          <div class="box-heading">Log in to continue to CONNECT.<br>New user? Sign up below.</div>
          <div class="box-buttons">
            <button type="button" @click="register" class="btn-white flex-1">Sign Up</button>
            <button type="button" @click="login" class="btn-transparent flex-1">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/events/EventBus';
export default {
  name: 'LockedContentPopup',
  props: {
    heading: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'wines'
    }
  },
  data () {
    return {
      isVisible: false,
    }
  },
  methods: {
    register() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({
        appState: { targetUrl: this.$route.fullPath },
        screen_hint: 'signup'
      });
    },
    login() {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: this.$route.fullPath }
      });
    },
    showPopup() {
      this.isVisible = false
      Bus.$emit('show-register-popup')
    },
    showThis() {
      this.isVisible = true
    },
    hideThis () {
      this.isVisible = false
    }
  },
  mounted() {
    Bus.$on('show-locked-content-popup', () => {
      console.log('show locked content popup')
      this.showThis()
    })
  },
  beforeDestroy() {
    Bus.$off('show-locked-content-popup')
  }
}
</script>

<style lang="scss" scoped>
.popup-container {
  max-width: 820px;
  text-align: center;
}
.locked-content {
  position: fixed;
  background-color: rgba(#000, 0.8);
  box: horizontal middle center;
  padding: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.video-button {
  margin-top: 30px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
  @media(min-width: 768px)
  {
    font-size: 24px;
    margin-top: 40px;
  }

  button {
    font-size: 14px;
    text-transform: none;
    font-weight: bold;
  }
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.preview {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: -60px;
  margin-top: 30px;
  max-width: 520px;
  z-index: 1;

  img {
    display: block;
    width: 100%;
  }

  @media(min-width: 1024px)
  {
    position: absolute;
    right: 30px;
    margin: 0;
    padding: 0;
    width: 400px;
    bottom: -30px;
  }

  @media(min-width: 1200px)
  {
    width: 600px;
    bottom: -60px;
  }
}

.box-buttons {
  margin-top: 20px;
  max-width: 600px;
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;

  button {
    width: 100%;
    margin-top: 10px;

    @media(min-width: 660px)
    {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  @media(min-width: 660px)
  {
    box: horizontal middle center;
  }
}
.box-heading {
  font-size: 29px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: 20px;
  position: relative;
  z-index: 2;
  @media(min-width: 768px)
  {
    font-size: 42px;
  }
}
.box {
  background-color: #232323;
  margin-left: -10px;
  margin-right: -10px;
  padding: 30px;
  position: relative;

  @media(min-width: 1024px)
  {
    padding-bottom: 60px;
  }

  @media(min-width: 768px)
  {
    margin-bottom: 70px;
  }

  @media(min-width: 1200px)
  {
    margin-bottom: 100px;
  }

  @media(min-width: 1300px)
  {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.heading {
  box: horizontal middle left;
  font-size: 18px;
  font-weight: bold;
  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}

.image {
  margin-top: 4rem;

  img {
    display: block;
    width: 100%;
  }

  .large {
    display: none;
    @media(min-width: 768px)
    {
      display: block;
    }
  }

  .small {
    @media(min-width: 768px)
    {
      display: none;
    }
  }
}

.lock {
  margin-right: 10px;
}
</style>
