<template>
    <div v-if="page">




        <!-- promo -->
        <div class="outer-container">
            <div class="inner-container">

                <div class="intro mt-16 md:mt-24">
                    <h1 class="uppercase" v-html="page.elements.heading.value"></h1>
                    <div class="intro-text">
                        {{page.elements.promo_copy.value}}
                    </div>
                </div>

<!--                <bleed-container bleed="left">-->
<!--                    <div class="section section2">-->
<!--                        <div class="grey-box">-->
<!--                            <div class="bleed-wrapper relative z-20">-->
<!--                                <div class="intro-text-landing" v-html="page.elements.promo_copy.value"></div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </bleed-container>-->
            </div>
        </div>
<!--        <div class="section section2">-->
<!--            <div class="grey-box">-->
<!--                <div class="outer-container">-->
<!--                    <div class="inner-container">-->
<!--                        <div class="intro-text-landing" v-html="page.elements.promo_copy.value"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="outer-container">-->
<!--            <div class="inner-container">-->

<!--                &lt;!&ndash; search &ndash;&gt;-->
<!--                <div class="search-hero mb-40">-->
<!--                    <bleed-container bleed="left">-->
<!--                        <div class="background-image absolute left-0 right-0 w-full h-full z-10">-->
<!--                            <img src="@/assets/search-hero.jpg"-->
<!--                                 class="block absolute left-0 top-0 w-full lg:w-9/10 h-full object-cover max-w-none z-10">-->
<!--                        </div>-->
<!--                        <div class="bleed-wrapper relative z-20">-->
<!--                            <div class="hero-content pr-8 lg:px-40 max-w-screen-lg">-->
<!--                                <div class="heading"><span>Browse</span><br>&amp; <span>Search</span></div>-->
<!--                                <div class="search-box mt-12 lg:mt-20 relative">-->
<!--                                    <form @submit.prevent="submitSearch">-->
<!--                                        <input type="text"-->
<!--                                               class="bg-white text-16 block w-full py-4 leading-3 px-4 mt-2 leading-1 text-black appearance-none focus:outline-none focus:shadow-inner md:h-28 md:px-10 rounded-none"-->
<!--                                               v-model="query" placeholder="Search for wineries and products">-->
<!--                                        <button type="button"-->
<!--                                                class="clear-button absolute right-48 text-black top-6 focus:outline-none hover:text-primary-500 h-16 w-16 text-center"-->
<!--                                                v-show="query.length" @click="query = ''">-->
<!--                                            <svg width="20" height="20" aria-hidden="true" focusable="false"-->
<!--                                                 data-prefix="fal" data-icon="times-square"-->
<!--                                                 class="svg-inline&#45;&#45;fa fa-times-square fa-w-14" role="img"-->
<!--                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">-->
<!--                                                <path fill="currentColor"-->
<!--                                                      d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v352zm-97.2-245.3L249.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L224 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"></path>-->
<!--                                            </svg>-->
<!--                                        </button>-->
<!--                                        <button type="submit"-->
<!--                                                class="search-button bg-grey-100 text-white text-16 hover:bg-grey-200 hover:text-white transition-colors duration-300 focus:outline-none text-center inline-block leading-1 border border-white flex flex-row items-center px-4 md:px-8 justify-center">-->
<!--                                            <svg aria-hidden="true" focusable="false" data-prefix="far"-->
<!--                                                 data-icon="search" class="svg-inline&#45;&#45;fa fa-search fa-w-16 mr-4"-->
<!--                                                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"-->
<!--                                                 width="20" height="20">-->
<!--                                                <path fill="currentColor"-->
<!--                                                      d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path>-->
<!--                                            </svg>-->
<!--                                            Search-->
<!--                                        </button>-->
<!--                                    </form>-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <div class="count-boxes"></div>-->
<!--                        </div>-->
<!--                    </bleed-container>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <!-- stories -->
<!--        <div class="outer-container">-->
<!--            <div class="inner-container mt-8 md:mt-20">-->
<!--                <homepage-story-tile v-for="(story, index) in page.elements.stories.value" :bleed="index % 2 === 0 ? 'right' : 'left'" :image="modular_content[story].elements.image.value" :headline="modular_content[story].elements.headline.value" :intro="modular_content[story].elements.intro.value" :link-label="modular_content[story].elements.link_label.value" :link-url="modular_content[story].elements.link_url.value" :key="`story${index}`" />-->
<!--            </div>-->
<!--        </div>-->

        <div class="outer-container mt-16">
            <div class="inner-container">
                <div class="nav-promo mt-16">

                    <div class="nav-promo-item item1">
                        <router-link :to="$route.path === '/home' ? '/expo?from=landing' : '/expo'" @mouseenter.native="shuffleLetters(true, 1)" @mouseleave.native="shuffleLetters(false, 1)">
                            <div class="text">
                                <div class="letter">E</div>
                                <div class="letter">X</div>
                                <div class="letter">P</div>
                                <div class="letter">O</div>
                            </div>
                            <div class="image">
                                <div class="large-image">
                                    <img class="image1" :src="page.elements.expo_image.value[0].url + '?w=1050&auto=format'" />
                                    <img class="image2" :src="page.elements.experience_image.value[0].url + '?w=1050&auto=format'" />
                                  <img class="image3" :src="page.elements.discover_image.value[0].url + '?w=1050&auto=format'" />
<!--                                    <img class="image4" :src="page.elements.explore_image.value[0].url + '?w=1050&auto=format'" />-->
                                </div>
                            </div>
                            <div class="arrow">
                                <img src="@/assets/arrow.png" />
                            </div>
                            <div class="description">
                                {{page.elements.expo_description.value}}
                            </div>
                        </router-link>
                    </div>

                    <div class="nav-promo-item item2">
                        <router-link :to="$route.path === '/home' ? '/experience?from=landing' : '/experience'" @mouseenter.native="shuffleLetters(true, 2)" @mouseleave.native="shuffleLetters(false, 2)">
                            <div class="text">
                              <div class="letter">E</div>
                              <div class="letter">X</div>
                              <div class="letter">P</div>
                              <div class="letter">E</div>
                              <div class="letter">R</div>
                              <div class="letter">I</div>
                              <div class="letter">E</div>
                              <div class="letter">N</div>
                              <div class="letter">C</div>
                              <div class="letter">E</div>
                            </div>
                            <div class="image">
                                <div class="large-image">
                                    <img class="image1" :src="page.elements.expo_image.value[0].url + '?w=1050&auto=format'" />
                                    <img class="image2" :src="page.elements.experience_image.value[0].url + '?w=1050&auto=format'" />
                                  <img class="image3" :src="page.elements.discover_image.value[0].url + '?w=1050&auto=format'" />
<!--                                    <img class="image4" :src="page.elements.explore_image.value[0].url + '?w=1050&auto=format'" />-->
                                </div>
                            </div>
                            <div class="arrow">
                                <img src="@/assets/arrow.png" />
                            </div>
                            <div class="description">
                                {{page.elements.experience_description.value}}
                            </div>
                        </router-link>
                    </div>

                    <div class="nav-promo-item item3">
                      <router-link :to="$route.path === '/home' ? '/discover?from=landing' : '/discover'" @mouseenter.native="shuffleLetters(true, 3)" @mouseleave.native="shuffleLetters(false, 3)">
                        <div class="text">
                          <div class="letter">D</div>
                          <div class="letter">I</div>
                          <div class="letter">S</div>
                          <div class="letter">C</div>
                          <div class="letter">O</div>
                          <div class="letter">V</div>
                          <div class="letter">E</div>
                          <div class="letter">R</div>
                        </div>
                        <div class="image">
                          <div class="large-image">
                            <img class="image1" :src="page.elements.expo_image.value[0].url + '?w=1050&auto=format'" />
                            <img class="image2" :src="page.elements.experience_image.value[0].url + '?w=1050&auto=format'" />
                            <img class="image3" :src="page.elements.discover_image.value[0].url + '?w=1050&auto=format'" />
<!--                            <img class="image4" :src="page.elements.explore_image.value[0].url + '?w=1050&auto=format'" />-->
                          </div>
                        </div>
                        <div class="arrow">
                          <img src="@/assets/arrow.png" />
                        </div>
                        <div class="description">
                          {{page.elements.discover_description.value}}
                        </div>
                      </router-link>
                    </div>

<!--                    <div class="nav-promo-item item4">-->
<!--                        <a href="#" @mouseenter="shuffleLetters(true, 4)" @mouseleave="shuffleLetters(false, 4)" v-if="!$auth.isAuthenticated" @click.prevent="showPopup">-->
<!--                            <div class="text">-->
<!--                                <div class="letter">-->
<!--                                    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">-->
<!--                                        <path d="M17.9375 10.375H16.9062V7.28125C16.9062 3.71484 13.9414 0.75 10.375 0.75C6.76562 0.75 3.84375 3.71484 3.84375 7.28125V10.375H2.8125C1.65234 10.375 0.75 11.3203 0.75 12.4375V20.6875C0.75 21.8477 1.65234 22.75 2.8125 22.75H17.9375C19.0547 22.75 20 21.8477 20 20.6875V12.4375C20 11.3203 19.0547 10.375 17.9375 10.375ZM13.4688 10.375H7.28125V7.28125C7.28125 5.60547 8.65625 4.1875 10.375 4.1875C12.0508 4.1875 13.4688 5.60547 13.4688 7.28125V10.375Z" fill="#E8490F"/>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                                <div class="letter">E</div>-->
<!--                                <div class="letter">X</div>-->
<!--                                <div class="letter">P</div>-->
<!--                                <div class="letter">L</div>-->
<!--                                <div class="letter">O</div>-->
<!--                                <div class="letter">R</div>-->
<!--                                <div class="letter">E</div>-->
<!--                            </div>-->
<!--                            <div class="image">-->
<!--                                <div class="large-image">-->
<!--                                    <img class="image1" :src="page.elements.discover_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                    <img class="image2" :src="page.elements.expo_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                    <img class="image3" :src="page.elements.experience_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                    <img class="image4" :src="page.elements.explore_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="arrow">-->
<!--                                <img src="@/assets/arrow.png" />-->
<!--                            </div>-->
<!--                            <div class="description">-->
<!--                                {{page.elements.explore_description.value}}-->
<!--                            </div>-->
<!--                        </a>-->
<!--                        <a :href="$route.path === '/home' ? '/explore?from=landing' : '/explore'" @mouseenter="shuffleLetters(true, 4)" @mouseleave="shuffleLetters(false, 4)" v-else>-->
<!--                            <div class="text">-->
<!--                                <div class="letter">E</div>-->
<!--                                <div class="letter">X</div>-->
<!--                                <div class="letter">P</div>-->
<!--                                <div class="letter">L</div>-->
<!--                                <div class="letter">O</div>-->
<!--                                <div class="letter">R</div>-->
<!--                                <div class="letter">E</div>-->
<!--                            </div>-->
<!--                            <div class="image">-->
<!--                                <div class="large-image">-->
<!--                                    <img class="image1" :src="page.elements.discover_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                    <img class="image2" :src="page.elements.expo_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                    <img class="image3" :src="page.elements.experience_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                    <img class="image4" :src="page.elements.explore_image.value[0].url + '?w=1050&auto=format'" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="arrow">-->
<!--                                <img src="@/assets/arrow.png" />-->
<!--                            </div>-->
<!--                            <div class="description">-->
<!--                                {{page.elements.explore_description.value}}-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->

                </div>
            </div>
        </div>

        <div class="mt-32 md:mt-48">
            <!-- goings on -->
            <going-ons :heading="page.elements.going_on_heading.value" :slides="goingOnSlides"/>
        </div>



        <!-- sponsors -->
        <div class="sponsor-container" v-if="page && page.elements.sponsor_images.value.length">
            <div class="outer-container">
                <div class="inner-container">
                    <sponsored-by :gallery="page.elements.sponsor_images.value" :show-heading="false" />
                </div>
            </div>
        </div>
        <div class="sponsor-container" v-if="page && page.elements.sponsor_links.value.length">
            <div class="outer-container">
                <div class="inner-container">
                    <sponsor-links :sponsors="page.elements.sponsor_links.value" :modular_content="modular_content" :show-heading="false" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Analytics from '@/analytics'
import $ from 'jquery';
import GoingOns from "@/components/GoingOns";
import SponsoredBy from "@/components/SponsoredBy";
import Kontent from "@/kontent";
import Vue from "vue";
import Bus from '@/events/EventBus';
// import BleedContainer from "@/components/BleedContainer";
import SponsorLinks from "../components/SponsorLinks.vue";

export default {
    name: 'Members',
    metaInfo() {
        if (!this.page) {
            return null
        }

        let meta = [
            {property: 'og:site_name', content: 'CONNECT'},
            {property: 'og:url', content: window.location.href},
            {
                property: 'og:title',
                content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
            },
            {property: 'og:description', content: this.page.elements.page__page_description.value},
            {
                name: 'title',
                content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
            },
            {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
            {
                name: 'description',
                content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
            },
            {name: 'twitter:card', content: 'summary_large_image'},
            {
                name: 'twitter:title',
                content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
            },
            {
                name: 'twitter:description',
                content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
            }
        ]

        // if there's an image
        if (this.page.elements.page__main_image.value.length) {
            meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
            meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
        }

        // if there's a type
        if (this.page.elements.page__page_type.value.length) {
            meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
        }

        return {
            title: this.page.elements.page__page_title.value,
            meta: meta
        }
    },
    components: {SponsorLinks, SponsoredBy, GoingOns},
    props: {},
    data() {
        return {
            page: null,
            modular_content: null,
            promo: null,
            goingOnSlides: [],
            showConversations: process.env.VUE_APP_SHOW_CONVERSATIONS,
            view: '',
            query: '',
            wineryHits: 0,
            productHits: 0,
            ready: false
        }
    },
    mounted() {
        Analytics.trackPage('Members Home')
        if (this.$auth.isAuthenticated) {
            this.$store.dispatch("getFavouriteProducts")
            this.$store.dispatch("getFavouriteWineries")
        }

        const self = this
        Kontent.getItemByType('home_page').then((res) => {
            this.page = res.data.items[0]
            this.modular_content = res.data.modular_content
            let g = []
            for (let i = 0; i < this.page.elements.going_on_slides.value.length; i++) {
                g.push(res.data.modular_content[this.page.elements.going_on_slides.value[i]])
                this.goingOnSlides = g
            }
            Vue.nextTick(function () {
                $(self.$el).find('.letter').each(function (i, el) {
                    $(el).css({marginRight: Math.random() * 1.2 + 'rem', opacity: 1})
                });
            })

            setTimeout(function () {
                window.prerenderReady = true
            }, 1000)
        })

        Kontent.getItemByType('promo').then((res) => {
            this.promo = res.data.items[0]
        })

        $(document).on('scroll', function () {
            if ($(window).scrollTop() > 500) {
                $('body').addClass('scrolled')
            } else {
                $('body').removeClass('scrolled')
            }
        });

    },
    methods: {
        submitSearch() {
            // console.log('submitsearch')
            // this.$store.commit('setQuery', this.query)
            this.$router.push('/expo?query=' + this.query)
        },
        showPopup() {
            Bus.$emit('show-locked-content-popup')
        },
        shuffleLetters(h, n) {
            $(this.$el).find('.item' + n + ' .letter').each(function (i, el) {
                $(el).css({marginRight: Math.random() * 1.2 + 'rem'})
            });
            $(this.$el).find('.item' + n + ' .letter:first').css({marginLeft: (Math.random()) * 2 - (Math.random() * 2) + 'rem'})
            if (h) {
                // hide all iamges, text, and arrows that are not this
                $('.nav-promo-item img').not('.image' + n).not('.arrow img').css('opacity', 0);
                $('.nav-promo-item .text').not('.item' + n + ' .text').css('opacity', 0);
                $('.nav-promo-item .arrow').not('.item' + n + ' .arrow').css('opacity', 0);

                // show this image, text, arrow
                $('.nav-promo-item .image' + n + ', .nav-promo-item.item' + n + ' .text').css('opacity', 1);
            } else {
                // show all arrows and text
                $('.nav-promo-item .text, .nav-promo-item .arrow').css('opacity', 1);

                // hide all images
                $('.nav-promo-item img').not('.arrow img').css('opacity', 0);

                // show relative images
                $('.nav-promo-item.item1 .image1').css('opacity', 1);
                $('.nav-promo-item.item2 .image2').css('opacity', 1);
                $('.nav-promo-item.item3 .image3').css('opacity', 1);
                $('.nav-promo-item.item4 .image4').css('opacity', 1);
            }
        }
    }
}
</script>

<style scoped lang="scss">

.hero-content {
    padding-top: 50px;
    padding-bottom: 50px;

    @media(min-width: 768px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.hero-content .heading {
    font-size: 30px;
    text-transform: uppercase;

    span {
        font-weight: bold;
    }

    line-height: 1.1;

    @media(min-width: 768px) {
        font-size: 68px;
    }
}

.helper {
    font-size: 9px;
    text-align: right;
    margin-top: 5px;
}

.search-button {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    @media (min-width: 768px) {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin-top: 0;
        width: auto;
        padding: 0 20px;
    }
}

.promo-banner {
    z-index: 20;

    //@media(min-width: 768px)
    //{
    //  position: sticky;
    //  top: 100px;
    //}
    //
    //@media(min-width: 1024px)
    //{
    //  top: 150px;
    //}

    .small {
        width: 300px;
        @media(min-width: 768px) {
            display: none;
        }
    }

    .large {
        width: 728px;
        display: none;
        @media(min-width: 768px) {
            display: block;
        }
    }

    .small, .large {
        padding: 10px;
        margin: auto;
        background-color: #000;
    }

    img {
        display: block;
    }
}

.going-on-container, .sponsor-container {
    margin-top: 110px;
    @media(min-width: 768px) {
        margin-top: 150px;
    }
}

.intro {
    @media(min-width: 768px) {
        display: flex;
        flex-direction: row;
    }
}

h1, .h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 2rem;

    @media(min-width: 768px) {
        font-size: 68px;
    }
}

h2 {
    font-size: 20px;
    font-weight: 600;

    @media(min-width: 768px) {
        font-size: 34px;
    }
}

.intro-text {
    color: #9A9A9A;
    margin-top: 20px;

    @media(min-width: 768px) {
        margin-top: 0;
        width: 300px;
        margin-left: 40px;
    }
}

.nav-promo {
    max-width: 1050px;
    margin-top: 50px;
}

.nav-promo-item {
    margin-top: 40px;

    .description {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 40%;
        text-align: left;
        text-shadow: 0 0 2px #000;
        opacity: 0;
        display: none;
        transform: translateX(20px);
        transition: all 0.3s ease;
        background-color: #000;
        padding: 10px;

        @media(min-width: 1025px) {
            display: block;
        }
    }

    a {
        display: inline-block;
        position: relative;
        height: 85px;
        width: 100%;

        @media(min-width: 768px) {
            height: 171px;
        }

        &:hover {
            //.image {
            //  opacity: 1;
            //}
            .arrow {
                transform: translateX(20px);
            }

            .description {
                opacity: 1;
                transform: none;
            }
        }
    }

    .text {
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        z-index: 2;
        white-space: nowrap;
        text-align: center;
        text-shadow: 0 0 10px rgba(#000, 0.5);

        .letter {
            display: inline-block;
            transition: all 1s ease;
            opacity: 0;
        }

        @media(min-width: 768px) {
            font-size: 38px;
        }
    }

    .arrow {
        position: absolute;
        bottom: 0;
        width: 55px;
        z-index: 3;
        transition: transform 0.3s ease, opacity 1s ease;

        @media(min-width: 768px) {
            width: 70px;
        }
    }

    .text {
        transition: opacity 1s ease;
    }

    .image {
        position: relative;
        height: 100%;
        z-index: 1;
        transition: opacity 0.3s ease;
        opacity: 0.7;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .large-image {
            position: absolute;
            height: 406%;

            img {
                opacity: 0;
                transition: opacity 1s ease;
            }

            @media(min-width: 768px) {
                height: 353%;
            }
        }
    }

    &.item1 {
        text-align: right;

        a {
            max-width: 360px;
            @media(min-width: 768px) {
                max-width: none;
            }
        }

        .text {
            @media(min-width: 768px) {
                left: 20%;
            }
            @media(min-width: 768px) {
                left: 35%;
            }
        }

        .image {
            margin-left: 15%;

            .image1 {
                opacity: 1;
            }

            .large-image {
                left: -18%;
                right: 0;
            }

            @media(min-width: 768px) {
                margin-left: 40%;
                .large-image {
                    left: -67%;
                }
            }
            @media(min-width: 1024px) {
                margin-left: 50%;
                .large-image {
                    left: -100%;
                }
            }
        }

        .arrow {
            left: 6%;
            @media(min-width: 768px) {
                left: 35%;
            }
            @media(min-width: 1024px) {
                left: 45%;
            }
        }
    }

    &.item2 {
        text-align: left;

        .text {
            right: 10%;
            @media(min-width: 768px) {
                right: 15%;
            }
        }

        a {
            max-width: 560px;
            @media(min-width: 768px) {
                max-width: none;
            }
        }

        .image {
            .image2 {
                opacity: 1;
            }

            margin-right: 5%;

            .large-image {
                left: 0;
                right: -5%;
                top: -130px;
            }

            @media(min-width: 768px) {
                margin-right: 20%;
                .large-image {
                    top: -217px;
                    right: -25%;
                }

            }
        }

        .arrow {
            right: 3%;
            @media(min-width: 768px) {
                right: 18%;
            }
        }

        .description {
            text-align: left;
            left: auto;
            right: 20%;
        }
    }

    &.item3 {
        text-align: center;
        @media(min-width: 768px) {
            text-align: left;
        }

        .text {
            right: 10%;
            @media(min-width: 768px) {
                right: auto;
                left: 20%;
            }
        }

        a {
            max-width: 360px;
            @media(min-width: 768px) {
                max-width: none;
            }
        }

        .image {
            .image3 {
                opacity: 1;
            }

            margin-left: 5%;
            margin-right: 35%;

            .large-image {
                left: -11%;
                right: -60%;
                top: auto;
                bottom: 0;
            }

            @media(min-width: 768px) {
                margin-left: 10%;
                .large-image {
                    left: -19%;
                    right: -64%;
                }
            }
            @media(min-width: 1024px) {
                margin-right: 55%;
                .large-image {
                    left: -29%;
                    right: -157%;
                }
            }
        }

        .arrow {
            right: 33%;
            @media(min-width: 1024px) {
                right: 52%;
            }
        }

        .description {
            left: 55%;
        }
    }

    &.item4 {
        text-align: right;

        a {
            max-width: 390px;
            @media(min-width: 768px) {
                max-width: none;
            }
        }

        .text {
            right: 20%;
            @media(min-width: 768px) {
                right: 5%;
            }
        }

        .image {
            margin-right: 30%;

            .image1 {
                opacity: 1;
            }

            .large-image {
                left: -18%;
                right: 0;
                top: auto;
                bottom: 0;
            }

            @media(min-width: 768px) {
                margin-left: 20%;
                margin-right: 10%;
                .large-image {
                    left: -67%;
                }
            }
            @media(min-width: 1024px) {
                margin-left: 30%;
                .large-image {
                    left: -100%;
                }
            }
        }

        .arrow {
            right: 27%;
            @media(min-width: 768px) {
                right: 8%;
            }
        }

        .description {
            text-align: left;
            left: auto;
            right: 12%;
        }
    }


}

.mouse {
    width: 26px;
    height: 42px;
    border: 2px solid #9A9A9A;
    border-radius: 24px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    // cursor: pointer;
    pointer-events: none;
    z-index: 2000;
    opacity: 1;
    display: none;
    transition: opacity 1s ease;
    color: #9A9A9A;
    @media(min-width: 1025px) {
        display: block;
    }

    .label {
        text-transform: uppercase;
        position: absolute;
        right: 30px;
        top: 7px;
    }
}

.mouse:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -2px;
    background-color: #9A9A9A;
    border-radius: 100%;
    width: 4px;
    height: 4px;
    opacity: 1;
    -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    -webkit-animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
    animation: scroll 1.5s -1s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

@-webkit-keyframes scroll {
    0%, 20% {
        -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
        transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    }
    10% {
        -webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        opacity: 0.01;
    }
}

@keyframes scroll {
    0%, 20% {
        -webkit-transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
        transform: translateY(0px) scaleY(1) scaleX(1) translateZ(0px);
    }
    10% {
        -webkit-transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        transform: translateY(0px) scaleY(1.2) scaleX(1.2) translateZ(0px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        transform: translateY(20px) scaleY(2.5) scaleX(0.5) translateZ(0px);
        opacity: 0.01;
    }
}

.section2 {
    padding-bottom: 60px;

    @media (min-width: 768px) {
        padding-right: 30%;
    }

    .grey-box {
        background-color: #111;
        font-size: 20px;
        font-weight: bold;
        padding: 20px;
        padding-bottom: 30px;


        @media(min-width: 768px) {
            font-size: 30px;
            padding: 40px;
            padding-bottom: 50px;
        }
    }

    .vertical-image {
        width: 30%;
        margin-left: auto;
        top: 50px;

        @media(min-width: 1024px) {
            width: 20%;
        }

        @media(min-width: 1500px) {
            width: 25%;
        }
    }

    .horizontal-image {
        width: 80%;
        margin-left: auto;

        @media(min-width: 1024px) {
            width: 60%;
        }

        img {
            margin-top: -60px;

            @media(min-width: 768px) {
                margin-top: -150px;
            }
        }

    }

}
</style>

<style lang="scss">
body.scrolled .mouse {
    opacity: 0;
}
</style>
