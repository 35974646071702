<template>
  <div id="app">
    <div class="loaded" v-if="!$auth.loading">
      <app-header v-if="!$route.meta.public && !$route.meta.both" />
      <public-header v-if="$route.meta.public && !$route.meta.both" />
      <app-header v-if="$auth.isAuthenticated && $route.meta.both" />
      <public-header v-if="!$auth.isAuthenticated && $route.meta.both" />
      <article>
        <vue-page-transition name="fade-in-up" class="page-transition">
          <router-view />
        </vue-page-transition>
      </article>
      <app-footer :mode="$route.meta.footerMode ? $route.meta.footerMode : 'grey'" :margin="$route.meta.footerMargin !== false"/>
    </div>
    <register-popup />
    <locked-content-popup />
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-0 w-full h-full z-100 bg-black bg-opacity-90" v-if="$auth.loading">
      <loading-spinner />
    </div>
  <map-popup />

  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import PublicHeader from "@/components/PublicHeader";
import LoadingSpinner from "@/components/LoadingSpinner";
import RegisterPopup from "@/components/RegisterPopup";
import LockedContentPopup from "@/components/LockedContentPopup";

import Vue from 'vue'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueCookies from 'vue-cookies'
import MapPopup from "./components/MapPopup.vue";
Vue.use(VueCookies)

export default {
  name: 'App',
  components: {
      MapPopup,
    RegisterPopup,
    LoadingSpinner,
    PublicHeader,
    AppFooter,
    AppHeader,
    LockedContentPopup
  },
  methods: {},
  watch: {
    '$route': function(r) {
      console.log('route', r)
      let pageToVisit = r.fullPath
      if (pageToVisit === '/') {
        pageToVisit = '/home'
      }
      if (pageToVisit === '/login') {
        pageToVisit = '/home'
      }
      if (pageToVisit === '/register') {
        pageToVisit = '/home'
      }
      if (pageToVisit === '/redirect') {
        pageToVisit = '/home'
      }
      if (pageToVisit === '/logout') {
        pageToVisit = '/home'
      }
      if (pageToVisit === '/404') {
        pageToVisit = '/home'
      }
      console.log('route change', r)
      if (!r.query.code && pageToVisit !== '/verify-email' && pageToVisit !== '/register-profile') {
        console.log('setting redirect', pageToVisit)
        this.$cookies.set('connect-redirect', pageToVisit,'30d','/');
      }
    }
  }


}
</script>

<style lang="scss">
</style>
