<template>
  <div class="going-ons bg-grey-100">
    <div class="outer-container">
      <div class="inner-container">

        <div class="md:flex md:flex-row">
          <div class="main-col md:w-2/3">
            <h2 class="relative z-20">{{heading}}</h2>
            <bleed-container bleed="left" class="h-full">
              <div class="bleed-wrapper relative z-20">
                <h3 class="uppercase absolute -mt-4 md:-mt-8">{{ currentTitle }}</h3>
              </div>
              <div class="carousel relative z10">
                <swiper ref="carousel" :options="swiperOptions" class="relative z-10" @slideChangeTransitionEnd="handleSlideChange">
                  <swiper-slide :key="`slide${index}`" v-for="(slide, index) in slides">
                    <div class="slide-image">
                      <img :src="`${slide.elements.image.value[0].url}?w=1000&auto=format`" class="block">
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="slide-count absolute right-0 bottom-0 bg-black p-8 text-white z-20 tracking-widest">
                  {{currentSlideLabel}} / 0{{slides.length}}
                </div>
              </div>
              <div class="bleed-wrapper mobile-thumbnails md:hidden">
                <button v-for="(image, index) in slides" :key="`button${index}`" type="button" class="focus:outline-none" :class="{'active': currentSlide === index}" @click="swiper.slideTo(index + 1, 500, true)">
                  <img :src="`${image.elements.image.value[0].url}?w=100&h=45&fit=crop&auto=format`" class="block">
                </button>
              </div>
            </bleed-container>
          </div>
          <div class="side-col md:w-1/3 md:flex md:flex-col md:pl-16">
            <div class="thumbnails hidden md:block">
              <div class="thumb" v-for="(image, index) in slides" :key="`thumb${index}`">
                <button type="button" class="focus:outline-none" :class="{'active': currentSlide === index}" @click="swiper.slideTo(index + 1, 500, true)">
                  <img :src="`${image.elements.image.value[0].url}?w=100&h=45&fit=crop&auto=format`" class="block">
                </button>
              </div>
            </div>
            <div class="cursor-pointer arrow flex-grow h-12 lg:h-24 md:h-auto hidden md:flex md:flex-row md:items-start md:justify-end" @click="swiper.slideNext(500, true)">
              <img src="@/assets/icon-arrow.png" alt="" class="md:mt-16">
            </div>
            <!--              <div class="image mt-12">-->
            <!--                <img src="@/assets/grass.jpg" class="block">-->
            <!--              </div>-->
            <div class="description mt-12 pt-12 border-t border-white border-opacity-10">
              {{currentDescription}}
            </div>
            <div class="cta mt-12" v-if="currentCtaLabel">
              <a :href="currentCtaUrl" class="btn-transparent" v-if="currentCtaUrl && currentCtaUrl.substr(0, 6) !== '/expo?'">
                {{ currentCtaLabel }}
              </a>
              <router-link :to="currentCtaUrl" class="btn-transparent" v-if="currentCtaUrl && currentCtaUrl.substr(0, 6) === '/expo?'">
                {{ currentCtaLabel }}
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BleedContainer from "@/components/BleedContainer";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
import Vue from 'vue'

const winerySorts = ['wineries_name_asc', 'wineries_name_desc', 'wineries_state_asc', 'wineries_state_desc', 'wineries_region_asc', 'wineries_region_desc']
const productSorts = ['products_name_asc', 'products_name_desc', 'products_state_asc', 'products_state_desc', 'products_region_asc', 'products_region_desc']

export default {
  name: 'GoingOns',
  props: {
    heading: {
      type: String,
      default: null
    },
    slides: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    BleedContainer,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        spaceBetween: 0,
        slidesPerView: 1,
        loop: true
      },
      currentSlide: 0
    }
  },
  computed: {
    swiper() {
      return this.$refs.carousel.$swiper
    },
    currentSlideLabel () {
      return '0' + (this.currentSlide + 1)
    },
    currentDescription () {
      return this.slides[this.currentSlide].elements.description.value
    },
    currentCtaLabel () {
      return this.slides[this.currentSlide].elements.cta_label.value
    },
    currentCtaUrl () {
      if (this.slides[this.currentSlide].elements.cta_url.value.substr(0, 6) !== '/expo?') {
        return this.slides[this.currentSlide].elements.cta_url.value
      } else {
        let url = this.slides[this.currentSlide].elements.cta_url.value
        for (let i = 0; i < winerySorts.length; i++) {
          const idx = winerySorts[i];
          const re = new RegExp(idx, 'g')
          url = url.replace(re, this.$store.state.winerySort)
        }
        for (let i = 0; i < productSorts.length; i++) {
          const idx = productSorts[i];
          const re = new RegExp(idx, 'g')
          url = url.replace(re, this.$store.state.productSort)
        }
        return url
      }
    },
    currentTitle () {
      return this.slides[this.currentSlide].elements.title.value
    }
  },
  methods: {
    handleSlideChange () {
      this.currentSlide = this.swiper.realIndex
    }
  },
  mounted() {
    const self = this
    this.$refs.carousel.$swiper.update()
    Vue.nextTick(function () {
      self.$refs.carousel.$swiper.update()
    })
  }
}
</script>

<style scoped lang="scss">
h1, .h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;

  @media(min-width: 768px)
  {
    font-size: 68px;
  }
}
h2 {
  font-size: 20px;
  font-weight: bold;

  @media(min-width: 768px)
  {
    font-size: 40px;
  }
}
h3 {
  font-size: 18px;
  font-weight: 600;

  @media(min-width: 768px)
  {
    font-size: 38px;
  }
}
.slide-image {
  height: 320px;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    height: 400px;
  }

  @media (min-width: 1500px) {
    height: 500px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.going-ons {

  padding-top: 110px;
  padding-bottom: 110px;

  @media(min-width: 768px)
  {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .carousel {
    margin-top: 20px;

    @media(min-width: 768px)
    {
      margin-top: 30px;
    }
  }
}

.mobile-thumbnails {
  margin-top: 10px;
  text-align: right;
  button {
    width: 50px;
    padding-left: 4px;
    padding-bottom: 4px;
    background-color: #fff;
    margin-left: 5px;

    &.active {
      background-color: #E8490F;
    }
  }
}

.thumbnails {
  margin-left: -5px;
  margin-right: -5px;
  .thumb {
    padding: 5px;
    width: 25%;
    display: inline-block;
  }
  button {
    padding-left: 4px;
    padding-bottom: 4px;
    background-color: #fff;

    &.active {
      background-color: #E8490F;
    }
  }
}
</style>
