/* eslint-disable no-case-declarations */
import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './styles/global.scss'
import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

import Toasted from 'vue-toasted';
Vue.use(Toasted)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import { Auth0Plugin } from './auth';

import $ from 'jquery'

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  onRedirectCallback: appState => {
      let r = '/home'
      if (Vue.$cookies.get('connect-redirect')) {
        r = '/redirect'
      }
    // router.push(
    //     appState && appState.targetUrl
    //         ? appState.targetUrl
    //         : window.location.pathname
    // );
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : r
    );
  }
});

if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
      });
}

Vue.filter('richtext', function (value, modContent) {
  // create a jquery div and put the html inside
  let div = $('<div></div>');
  div.html(value);

  div.find('object[type="application/kenticocloud"]').each(function (i, el) {
    let obj = null
    if (modContent[$(el).attr('data-codename')]) {
      obj = modContent[$(el).attr('data-codename')]
    }
    if (obj) {
      switch (obj.system.type) {
        case 'experience_content_image':
          console.log(obj)

          let imageHtml = '<img src="' + obj.elements.image.value[0].url + '?w=1000&auto=format">'

          let html = ''
          if(obj.elements.url.value.length) {
            html = '<div class="content-image"><a href="' + obj.elements.url.value + '">' + imageHtml + '</a></div>'
          } else {
            html = '<div class="content-image">' + imageHtml + '</div>'
          }

          $(el).replaceWith(html)
          break;
      }
    }
  })

  return div.html()
})



// lazysizes
require('lazysizes')
require('lazysizes/plugins/blur-up/ls.blur-up')
require('lazysizes/plugins/object-fit/ls.object-fit')
require('lazysizes/plugins/respimg/ls.respimg')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
