<template>
  <header class="relative h-20 md:h-40 lg:h-60" :class="[ {'show-mobile-menu': showMobileMenu} ]">

    <div class="mobile-menu">
      <div class="inner flex flex-row items-center">

        <button type="button" class="close-button focus:outline-none appearance-none" @click="hideMobileMenu">
          <svg xmlns="http://www.w3.org/2000/svg" width="29.165" height="29.165" viewBox="0 0 29.165 29.165" class="stroke-current">
            <g id="X" transform="translate(0.582 0.582)">
              <path id="Stroke_1" data-name="Stroke 1" d="M0,0,28,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>
              <path id="Stroke_3" data-name="Stroke 3" d="M28,0,0,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>
            </g>
          </svg>
        </button>
        <ul class="mobile-nav block w-full text-18 font-semibold p-8">
          <li>
            <router-link @click="hideMobileMenu" to="/home" class="relative uppercase py-8 border-b border-grey-500 border-opacity-30 block">
              Home
            </router-link>
          </li>
          <li>
<!--            <a @click.prevent="showPopup" href="#" class="flex flex-row items-center relative uppercase py-8 border-b border-grey-500 border-opacity-30 block" v-if="!$auth.isAuthenticated">-->
<!--              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">-->
<!--                <path d="M9.875 5.75H9.3125V4.0625C9.3125 2.11719 7.69531 0.5 5.75 0.5C3.78125 0.5 2.1875 2.11719 2.1875 4.0625V5.75H1.625C0.992188 5.75 0.5 6.26562 0.5 6.875V11.375C0.5 12.0078 0.992188 12.5 1.625 12.5H9.875C10.4844 12.5 11 12.0078 11 11.375V6.875C11 6.26562 10.4844 5.75 9.875 5.75ZM7.4375 5.75H4.0625V4.0625C4.0625 3.14844 4.8125 2.375 5.75 2.375C6.66406 2.375 7.4375 3.14844 7.4375 4.0625V5.75Z" fill="#E8490F"/>-->
<!--              </svg>-->
<!--              Expo-->
<!--            </a>-->
            <router-link @click="hideMobileMenu" :to="$route.path === '/home' ? '/expo?from=mobilemenu' : '/expo'" class="relative uppercase py-8 border-b border-grey-500 border-opacity-30 block">
              Expo
            </router-link>
          </li>

          <li>
            <router-link @click="hideMobileMenu" :to="$route.path === '/home' ? '/experience?from=mobilemenu' : '/experience'" class="relative uppercase py-8 border-b border-grey-500 border-opacity-30 block">
              Experience
            </router-link>
          </li>
          <li>
            <router-link @click="hideMobileMenu" :to="$route.path === '/home' ? '/discover?from=mobilemenu' : '/discover'" class="relative uppercase py-8 border-b border-grey-500 border-opacity-30 block">
              Discover
            </router-link>
          </li>

          <li v-if="$auth.isAuthenticated">
            <router-link to="/profile" @click="hideMobileMenu" class="font-normal text-16 flex icon-button focus:outline-none hover:text-primary-500 transition-colors flex-row items-center space-x-4 py-8 border-b border-grey-500 border-opacity-30">
              <svg aria-hidden="true" width="18" height="18" focusable="false" data-prefix="far" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg>
              <span class="label">My Profile</span>
            </router-link>
          </li>
          <li v-if="$auth.isAuthenticated">
            <button @click="logout" class="font-normal text-16 flex icon-button focus:outline-none hover:text-primary-500 transition-colors flex-row items-center space-x-4 py-8">
              <svg aria-hidden="true" width="20" height="20" focusable="false" data-prefix="fas" data-icon="sign-out" class="svg-inline--fa fa-sign-out fa-w-16 fill-current mt-px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z"></path></svg>
              <span class="label">Log out</span>
            </button>
          </li>
          <li v-if="!$auth.isAuthenticated">
            <button @click="login" class="font-normal text-16 flex icon-button focus:outline-none hover:text-primary-500 transition-colors flex-row items-center space-x-4 py-8">
              <span class="label">Log in</span>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="main fixed left-0 right-0 top-0 z-50 h-20 md:h-40 lg:h-60 border-b border-white border-opacity-10" :class="[{'bg-black': !$route.meta.transparentHeader}]">
      <div class="outer-container">
        <div class="inner-container h-20 md:h-40 lg:h-60 flex flex-row justify-between items-center">
          <router-link @click="hideMobileMenu" to="/home" class="brand">
<!--            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="310" height="45.358" viewBox="0 0 310 45.358">-->
<!--              <defs>-->
<!--                <clipPath id="clip-path">-->
<!--                  <path id="Clip_2" data-name="Clip 2" d="M0,0H21.331V23.659H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--                <clipPath id="clip-path-2">-->
<!--                  <path id="Clip_5" data-name="Clip 5" d="M0,0H23.115V23.659H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--                <clipPath id="clip-path-4">-->
<!--                  <path id="Clip_25" data-name="Clip 25" d="M0,0H6.388V6.623H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--                <clipPath id="clip-path-5">-->
<!--                  <path id="Clip_46" data-name="Clip 46" d="M0,0H4.823V6.531H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--                <clipPath id="clip-path-6">-->
<!--                  <path id="Clip_49" data-name="Clip 49" d="M0,0H5.872V6.623H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--                <clipPath id="clip-path-7">-->
<!--                  <path id="Clip_56" data-name="Clip 56" d="M0,23.659H309.73V0H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--                <clipPath id="clip-path-8">-->
<!--                  <path id="Clip_15" data-name="Clip 15" d="M0,8.179H197V0H0Z" fill="none"/>-->
<!--                </clipPath>-->
<!--              </defs>-->
<!--              <g id="Lockup_Connect_Copy" data-name="Lockup_Connect Copy" transform="translate(0 21.358)">-->
<!--                <rect id="Lockup_Connect_background" data-name="Lockup_Connect background" width="310" height="24" fill="none"/>-->
<!--                <g id="Group_59" data-name="Group 59" transform="translate(0 0.286)">-->
<!--                  <g id="Group_3" data-name="Group 3" transform="translate(0 0)">-->
<!--                    <path id="Clip_2-2" data-name="Clip 2" d="M0,0H21.331V23.659H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_3-2" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">-->
<!--                      <path id="Fill_1" data-name="Fill 1" d="M21.331,17.255a7.848,7.848,0,0,1-2,3.339,9.9,9.9,0,0,1-3.513,2.263,12.068,12.068,0,0,1-4.4.8,11.141,11.141,0,0,1-9.932-5.842A12.269,12.269,0,0,1,0,11.829,12.436,12.436,0,0,1,.877,7.167,11.585,11.585,0,0,1,3.26,3.4,10.974,10.974,0,0,1,6.892.915,11.3,11.3,0,0,1,11.416,0,11.36,11.36,0,0,1,17.55,1.677a7.85,7.85,0,0,1,3.529,4.31L16.815,8.058a5.11,5.11,0,0,0-2.069-2.424,6.149,6.149,0,0,0-3.331-.9A6.034,6.034,0,0,0,6.726,6.741a7.293,7.293,0,0,0-1.816,5.088,7.309,7.309,0,0,0,1.824,5.088,6.019,6.019,0,0,0,4.681,2.023,6.069,6.069,0,0,0,3.5-1.011,5.027,5.027,0,0,0,2.021-2.713Z" transform="translate(0 0)" fill="#fff"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                  <g id="Group_6" data-name="Group 6" transform="translate(108.864)">-->
<!--                    <path id="Clip_5-2" data-name="Clip 5" d="M0,0H23.115V23.659H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_6-2" data-name="Group 6" transform="translate(0 0)" clip-path="url(#clip-path-2)">-->
<!--                      <path id="Fill_4" data-name="Fill 4" d="M11.542,23.659a12.278,12.278,0,0,1-3.1-.385,11.2,11.2,0,0,1-5.155-2.989A11.293,11.293,0,0,1,1.5,17.864,11.805,11.805,0,0,1,.375,15a13.589,13.589,0,0,1,0-6.34A11.815,11.815,0,0,1,1.5,5.794,11.3,11.3,0,0,1,3.286,3.375,11.2,11.2,0,0,1,8.441.385a12.676,12.676,0,0,1,6.2,0,11.1,11.1,0,0,1,6.963,5.409A11.789,11.789,0,0,1,22.738,8.66a13.515,13.515,0,0,1,0,6.34,11.779,11.779,0,0,1-1.131,2.865,11.323,11.323,0,0,1-1.794,2.42,11.264,11.264,0,0,1-5.169,2.989A12.3,12.3,0,0,1,11.542,23.659Zm0-18.924a6.237,6.237,0,0,0-4.737,2.03A7.169,7.169,0,0,0,4.91,11.83,7.2,7.2,0,0,0,6.805,16.9a6.225,6.225,0,0,0,4.737,2.039A6.226,6.226,0,0,0,16.279,16.9a7.2,7.2,0,0,0,1.894-5.072,7.17,7.17,0,0,0-1.894-5.064A6.238,6.238,0,0,0,11.542,4.735Z" transform="translate(0 0)" fill="#fff"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                  <path id="Fill_7" data-name="Fill 7" d="M0,0H4.074l9.2,13.419V0h4.784V23.017h-4.01L4.784,9.6V23.017H0Z" transform="translate(154.51 0.321)" fill="#fff"/>-->
<!--                  <path id="Fill_9" data-name="Fill 9" d="M0,0H4.074l9.2,13.419V0h4.784V23.017H14.052L4.784,9.6V23.017H0Z" transform="translate(178.494 0.321)" fill="#fff"/>-->
<!--                  <path id="Fill_11" data-name="Fill 11" d="M0,0H13.862V4.671H4.784V8.812h8.084v4.671H4.784v4.863h9.079v4.671H0Z" transform="translate(219.734 0.321)" fill="#fff"/>-->
<!--                  <g id="Group_15" data-name="Group 15" transform="translate(242.502)">-->
<!--                    <path id="Clip_14-2" data-name="Clip 14" d="M0,0H21.331V23.659H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_15-2" data-name="Group 15" transform="translate(0 0)" clip-path="url(#clip-path)">-->
<!--                      <path id="Fill_13" data-name="Fill 13" d="M21.331,17.255a7.848,7.848,0,0,1-2,3.339,9.9,9.9,0,0,1-3.513,2.263,12.068,12.068,0,0,1-4.4.8,11.141,11.141,0,0,1-9.932-5.842A12.27,12.27,0,0,1,0,11.829,12.437,12.437,0,0,1,.876,7.167,11.588,11.588,0,0,1,3.26,3.4,10.986,10.986,0,0,1,6.892.915,11.307,11.307,0,0,1,11.415,0a11.36,11.36,0,0,1,6.134,1.677,7.85,7.85,0,0,1,3.529,4.31L16.815,8.058a5.112,5.112,0,0,0-2.068-2.424,6.147,6.147,0,0,0-3.331-.9A6.034,6.034,0,0,0,6.726,6.741,7.3,7.3,0,0,0,4.91,11.829a7.31,7.31,0,0,0,1.824,5.088,6.019,6.019,0,0,0,4.681,2.023,6.069,6.069,0,0,0,3.5-1.011,5.028,5.028,0,0,0,2.021-2.713Z" transform="translate(0 0)" fill="#fff"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                  <path id="Fill_16" data-name="Fill 16" d="M18.015,0V4.671H11.384V23.017H6.6V4.671H0V0Z" transform="translate(291.715 0.321)" fill="#fff"/>-->
<!--                  <path id="Fill_18" data-name="Fill 18" d="M3.538,0V.662H.679V2.668H3.23V3.33H.679V5.777H3.538v.662H0V0Z" transform="translate(42.025 0.092)" fill="#fff"/>-->
<!--                  <path id="Fill_20" data-name="Fill 20" d="M4.361,6.439,2.579,3.716.8,6.439H0l2.181-3.33L.145,0H.96L2.588,2.493,4.217,0h.8L2.986,3.1l2.18,3.339Z" transform="translate(45.925 0.092)" fill="#fff"/>-->
<!--                  <path id="Fill_22" data-name="Fill 22" d="M.679,6.439H0V0H2.434A1.82,1.82,0,0,1,4.307,1.849,1.825,1.825,0,0,1,2.434,3.707H.679V6.439Zm0-5.777V3.045H2.434a1.178,1.178,0,0,0,1.186-1.2A1.16,1.16,0,0,0,2.434.662Z" transform="translate(51.67 0.092)" fill="#fff"/>-->
<!--                  <g id="Group_26" data-name="Group 26" transform="translate(56.357)">-->
<!--                    <path id="Clip_25-2" data-name="Clip 25" d="M0,0H6.388V6.623H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_26-2" data-name="Group 26" transform="translate(0 0)" clip-path="url(#clip-path-4)">-->
<!--                      <path id="Fill_24" data-name="Fill 24" d="M3.194,6.623A3.118,3.118,0,0,1,.922,5.662,3.394,3.394,0,0,1,0,3.293,3.336,3.336,0,0,1,.922.95,3.136,3.136,0,0,1,3.194,0,3.135,3.135,0,0,1,5.467.95a3.336,3.336,0,0,1,.922,2.343,3.394,3.394,0,0,1-.922,2.369A3.117,3.117,0,0,1,3.194,6.623Zm0-5.952A2.53,2.53,0,0,0,.706,3.293,2.55,2.55,0,0,0,3.194,5.952,2.55,2.55,0,0,0,5.682,3.293,2.53,2.53,0,0,0,3.194.672Z" transform="translate(0 0)" fill="#fff"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                  <path id="Fill_27" data-name="Fill 27" d="M3.538,0V.662H.679V2.668H3.23V3.33H.679V5.777H3.538v.662H0V0Z" transform="translate(42.025 8.61)" fill="#fff"/>-->
<!--                  <path id="Fill_29" data-name="Fill 29" d="M4.361,6.439,2.579,3.717.8,6.439H0l2.181-3.33L.145,0H.96L2.588,2.493,4.217,0h.8L2.986,3.1l2.18,3.339Z" transform="translate(45.925 8.61)" fill="#fff"/>-->
<!--                  <path id="Fill_31" data-name="Fill 31" d="M.679,6.439H0V0H2.434A1.82,1.82,0,0,1,4.307,1.849,1.825,1.825,0,0,1,2.434,3.707H.679V6.439Zm0-5.777V3.045H2.434a1.178,1.178,0,0,0,1.186-1.2A1.16,1.16,0,0,0,2.434.662Z" transform="translate(51.67 8.61)" fill="#fff"/>-->
<!--                  <path id="Fill_33" data-name="Fill 33" d="M.678,0V5.777h3.2v.662H0V0Z" transform="translate(56.719 8.61)" fill="#fff"/>-->
<!--                  <path id="Fill_35" data-name="Fill 35" d="M3.194,6.623A3.118,3.118,0,0,1,.922,5.662,3.394,3.394,0,0,1,0,3.293,3.336,3.336,0,0,1,.922.95,3.135,3.135,0,0,1,3.194,0,3.135,3.135,0,0,1,5.467.95a3.336,3.336,0,0,1,.922,2.343,3.394,3.394,0,0,1-.922,2.369A3.118,3.118,0,0,1,3.194,6.623Zm0-5.952A2.53,2.53,0,0,0,.706,3.293,2.55,2.55,0,0,0,3.194,5.952,2.55,2.55,0,0,0,5.682,3.293,2.53,2.53,0,0,0,3.194.672Z" transform="translate(60.646 8.518)" fill="#fff"/>-->
<!--                  <path id="Fill_37" data-name="Fill 37" d="M.678,6.439H0V0H2.289a1.9,1.9,0,0,1,1.33.521,1.788,1.788,0,0,1,.543,1.3,1.9,1.9,0,0,1-.406,1.222,1.757,1.757,0,0,1-1.078.608L4.524,6.439H3.7L1.927,3.68H.678V6.439Zm0-5.777V3.017H2.271a1.16,1.16,0,0,0,1.2-1.2A1.143,1.143,0,0,0,2.271.662Z" transform="translate(67.893 8.61)" fill="#fff"/>-->
<!--                  <path id="Fill_39" data-name="Fill 39" d="M3.538,0V.662H.679V2.668H3.23V3.33H.679V5.777H3.538v.662H0V0Z" transform="translate(73.059 8.61)" fill="#fff"/>-->
<!--                  <path id="Fill_41" data-name="Fill 41" d="M3.538,0V.662H.679V2.668H3.23V3.33H.679V5.777H3.538v.662H0V0Z" transform="translate(42.025 17.128)" fill="#fff"/>-->
<!--                  <path id="Fill_43" data-name="Fill 43" d="M1.964,6.439H0V0H1.964A3.077,3.077,0,0,1,4.178.9a3.226,3.226,0,0,1,.9,2.289,3.311,3.311,0,0,1-.9,2.317A3.042,3.042,0,0,1,1.964,6.439ZM.678.662V5.777H2A2.43,2.43,0,0,0,4.37,3.192,2.373,2.373,0,0,0,2,.662Z" transform="translate(46.486 17.128)" fill="#fff"/>-->
<!--                  <g id="Group_47" data-name="Group 47" transform="translate(52.421 17.128)">-->
<!--                    <path id="Clip_46-2" data-name="Clip 46" d="M0,0H4.823V6.531H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_47-2" data-name="Group 47" transform="translate(0 0)" clip-path="url(#clip-path-5)">-->
<!--                      <path id="Fill_45" data-name="Fill 45" d="M0,4.029V0H.679V4.029a1.731,1.731,0,1,0,3.457,0V0h.687V4.029a2.392,2.392,0,0,1-2.416,2.5A2.39,2.39,0,0,1,0,4.029" fill="#fff"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                  <g id="Group_50" data-name="Group 50" transform="translate(58.103 17.035)">-->
<!--                    <path id="Clip_49-2" data-name="Clip 49" d="M0,0H5.872V6.623H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_50-2" data-name="Group 50" transform="translate(0 0)" clip-path="url(#clip-path-6)">-->
<!--                      <path id="Fill_48" data-name="Fill 48" d="M3.167,6.623A3.169,3.169,0,0,1,0,3.293,3.154,3.154,0,0,1,3.167,0,2.8,2.8,0,0,1,5.8,1.509l-.606.3A2.2,2.2,0,0,0,3.167.672,2.46,2.46,0,0,0,.706,3.293,2.481,2.481,0,0,0,3.167,5.952a2.172,2.172,0,0,0,2.09-1.279l.615.285A2.788,2.788,0,0,1,3.167,6.623" fill="#fff"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                  <path id="Fill_51" data-name="Fill 51" d="M.724,6.439H0L2.534,0h.616L5.691,6.439H4.949L4.334,4.866h-3L.724,6.439ZM2.841,1.021,1.6,4.2H4.081Z" transform="translate(64.147 17.128)" fill="#fff"/>-->
<!--                  <path id="Fill_53" data-name="Fill 53" d="M4.841.662H2.76V6.439H2.081V.662H0V0H4.841Z" transform="translate(68.988 17.128)" fill="#fff"/>-->
<!--                  <path id="Clip_56-2" data-name="Clip 56" d="M0,23.659H309.73V0H0Z" fill="none"/>-->
<!--                  <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path-7)">-->
<!--                    <path id="Fill_55" data-name="Fill 55" d="M0,6.439H.679V0H0Z" transform="translate(74.507 17.128)" fill="#fff"/>-->
<!--                    <path id="Fill_57" data-name="Fill 57" d="M3.194,6.623A3.118,3.118,0,0,1,.922,5.662,3.394,3.394,0,0,1,0,3.293,3.336,3.336,0,0,1,.922.95,3.135,3.135,0,0,1,3.194,0,3.135,3.135,0,0,1,5.467.95a3.336,3.336,0,0,1,.922,2.344,3.394,3.394,0,0,1-.922,2.369A3.118,3.118,0,0,1,3.194,6.623Zm0-5.952A2.531,2.531,0,0,0,.706,3.293,2.55,2.55,0,0,0,3.194,5.952,2.55,2.55,0,0,0,5.682,3.293,2.53,2.53,0,0,0,3.194.671Z" transform="translate(76.045 17.036)" fill="#fff"/>-->
<!--                    <path id="Fill_58" data-name="Fill 58" d="M.642,0,4.189,5.17V0h.688V6.439H4.244L.679,1.279v5.16H0V0Z" transform="translate(83.292 17.128)" fill="#fff"/>-->
<!--                  </g>-->
<!--                </g>-->
<!--              </g>-->
<!--              <g id="Logo_Horizontal_Stack" data-name="Logo_Horizontal Stack">-->
<!--                <rect id="Logo_Horizontal_Stack_background" data-name="Logo_Horizontal Stack background" width="197" height="8.717" fill="none"/>-->
<!--                <g id="Group_33" data-name="Group 33" transform="translate(0 0.348)">-->
<!--                  <path id="Fill_1-2" data-name="Fill 1" d="M1.774,7.91H0L3.141,0H4.5L7.649,7.909H5.831l-.5-1.322H2.283L1.774,7.91ZM3.819,2.622,2.836,5.153H4.78L3.819,2.622Z" transform="translate(0 0.113)" fill="#fff"/>-->
<!--                  <g id="Group_32" data-name="Group 32" transform="translate(0 0)">-->
<!--                    <path id="Fill_2" data-name="Fill 2" d="M0,4.847V0H1.672V4.847a1.465,1.465,0,1,0,2.926,0V0H6.271V4.847a3.062,3.062,0,0,1-3.13,3.164A3.064,3.064,0,0,1,0,4.847" transform="translate(8.08 0.113)" fill="#fff"/>-->
<!--                    <path id="Fill_4-2" data-name="Fill 4" d="M6.068,1.209,5.1,2.531a3.035,3.035,0,0,0-2-.915c-.565,0-.949.26-.949.644,0,1.344,4.045.531,4.045,3.412A2.636,2.636,0,0,1,3.277,8.136,4.488,4.488,0,0,1,0,6.632l1.074-1.22a2.986,2.986,0,0,0,2.2,1.1c.712,0,1.2-.316,1.2-.825,0-1.333-4.011-.452-4.011-3.3A2.521,2.521,0,0,1,3.232,0,4.117,4.117,0,0,1,6.068,1.209" transform="translate(15.177 0)" fill="#fff"/>-->
<!--                    <path id="Fill_6" data-name="Fill 6" d="M6.294,1.6H3.977V7.91H2.305V1.6H0V0H6.294Z" transform="translate(21.946 0.113)" fill="#fff"/>-->
<!--                    <path id="Fill_8" data-name="Fill 8" d="M1.672,7.91H0V0H3.051A2.547,2.547,0,0,1,4.8.712a2.387,2.387,0,0,1,.749,1.729A2.327,2.327,0,0,1,4.283,4.633L6.35,7.909H4.316L2.531,4.893H1.672V7.91Zm0-6.294v1.83H2.881a.929.929,0,0,0,.972-.926.93.93,0,0,0-.972-.9Z" transform="translate(29.302 0.113)" fill="#fff"/>-->
<!--                    <path id="Fill_10" data-name="Fill 10" d="M1.774,7.909H0L3.141,0H4.5L7.649,7.909H5.831l-.5-1.322H2.282L1.774,7.909ZM3.819,2.621,2.836,5.152H4.78l-.96-2.531Z" transform="translate(36.139 0.113)" fill="#fff"/>-->
<!--                    <path id="Fill_12" data-name="Fill 12" d="M1.672,0V6.305H5.3v1.6H0V0Z" transform="translate(44.682 0.113)" fill="#fff"/>-->
<!--                    <path id="Clip_15-2" data-name="Clip 15" d="M0,8.179H197V0H0Z" transform="translate(0 0)" fill="none"/>-->
<!--                    <g id="Group_32-2" data-name="Group 32" transform="translate(0 0)" clip-path="url(#clip-path-8)">-->
<!--                      <path id="Fill_14" data-name="Fill 14" d="M0,7.91H1.672V0H0Z" transform="translate(51.09 0.113)" fill="#fff"/>-->
<!--                      <path id="Fill_16-2" data-name="Fill 16" d="M1.774,7.909H0L3.141,0H4.5L7.649,7.909H5.831l-.5-1.322H2.282L1.774,7.909ZM3.819,2.621,2.836,5.152H4.78l-.96-2.531Z" transform="translate(53.655 0.113)" fill="#fff"/>-->
<!--                      <path id="Fill_17" data-name="Fill 17" d="M1.424,0l3.22,4.61V0H6.316V7.91h-1.4L1.672,3.3V7.91H0V0Z" transform="translate(62.199 0.113)" fill="#fff"/>-->
<!--                      <path id="Fill_18-2" data-name="Fill 18" d="M11.277,0,8.655,7.921H7.311L5.65,3.333,4,7.921H2.644L0,0H1.785L3.356,5.1,5.085,0H6.249L7.977,5.1,9.526,0Z" transform="translate(71.962 0.102)" fill="#fff"/>-->
<!--                      <path id="Fill_19" data-name="Fill 19" d="M0,7.91H1.672V0H0Z" transform="translate(84.167 0.113)" fill="#fff"/>-->
<!--                      <path id="Fill_20-2" data-name="Fill 20" d="M1.424,0l3.22,4.61V0H6.316V7.91h-1.4L1.672,3.3V7.91H0V0Z" transform="translate(87.263 0.113)" fill="#fff"/>-->
<!--                      <path id="Fill_21" data-name="Fill 21" d="M4.848,0V1.6H1.673V3.028H4.5v1.6H1.673V6.305H4.848v1.6H0V0Z" transform="translate(95.004 0.113)" fill="#fff"/>-->
<!--                      <path id="Fill_22-2" data-name="Fill 22" d="M0,0H2.034l2.35,5.446L6.848,0H8.554V7.91H6.881V3.265L4.859,7.91H3.65L1.672,3.265V7.91H0Z" transform="translate(111.817 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_23" data-name="Fill 23" d="M1.774,7.909H0L3.142,0H4.5L7.649,7.909H5.831l-.5-1.322H2.283L1.774,7.909ZM3.819,2.621,2.836,5.152H4.78l-.96-2.531Z" transform="translate(121.264 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_24-2" data-name="Fill 24" d="M2.712,7.91H0V0H2.712A4.06,4.06,0,0,1,5.573,1.117a3.869,3.869,0,0,1,1.15,2.8,3.972,3.972,0,0,1-1.15,2.855A4.024,4.024,0,0,1,2.712,7.91ZM1.673,1.6V6.3H2.825a2.209,2.209,0,0,0,2.17-2.384A2.173,2.173,0,0,0,2.825,1.6Z" transform="translate(129.807 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_25" data-name="Fill 25" d="M4.847,0V1.6H1.673V3.028H4.5v1.6H1.673V6.305H4.847v1.6H0V0Z" transform="translate(137.729 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_26" data-name="Fill 26" d="M4.034,8.136A3.962,3.962,0,0,1,0,4.068,3.962,3.962,0,0,1,4.034,0,3.967,3.967,0,0,1,8.079,4.068,3.968,3.968,0,0,1,4.034,8.136Zm0-6.509A2.329,2.329,0,0,0,1.717,4.068a2.32,2.32,0,1,0,4.633,0A2.329,2.329,0,0,0,4.034,1.627Z" transform="translate(146.148 0.044)" fill="#E8490F"/>-->
<!--                      <path id="Fill_27-2" data-name="Fill 27" d="M0,4.847V0H1.672V4.847a1.465,1.465,0,1,0,2.926,0V0H6.271V4.847a3.062,3.062,0,0,1-3.13,3.164A3.064,3.064,0,0,1,0,4.847" transform="translate(155.426 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_28" data-name="Fill 28" d="M1.672,7.91H0V0H3.051A2.547,2.547,0,0,1,4.8.712a2.387,2.387,0,0,1,.749,1.729A2.327,2.327,0,0,1,4.283,4.633L6.35,7.909H4.317L2.531,4.893H1.672V7.91Zm0-6.294v1.83H2.881a.929.929,0,0,0,.972-.926.93.93,0,0,0-.972-.9Z" transform="translate(163.122 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_29-2" data-name="Fill 29" d="M11.277,0,8.655,7.921H7.311L5.65,3.333,4,7.921H2.644L0,0H1.785L3.356,5.1,5.085,0H6.249L7.977,5.1,9.526,0Z" transform="translate(172.512 0.145)" fill="#E8490F"/>-->
<!--                      <path id="Fill_30" data-name="Fill 30" d="M1.774,7.909H0L3.141,0H4.5L7.649,7.909H5.831l-.5-1.322H2.282L1.774,7.909ZM3.819,2.621,2.836,5.152H4.78L3.819,2.621Z" transform="translate(183.09 0.157)" fill="#E8490F"/>-->
<!--                      <path id="Fill_31-2" data-name="Fill 31" d="M2.045,0,3.8,2.87,5.548,0H7.5L4.621,4.565V7.91H2.938V4.565L0,0Z" transform="translate(189.498 0.157)" fill="#E8490F"/>-->
<!--                    </g>-->
<!--                  </g>-->
<!--                </g>-->
<!--              </g>-->
<!--            </svg>-->
            <img src="@/assets/brand.png" />
          </router-link>

          <div class="flex flex-row justify-end items-center">
          <nav class="main-nav hidden md:block text-14 lg:text-16 mr-6 lg:mr-8 xl:mr-14">
            <ul class="flex flex-row space-x-4 h-20 md:h-40 lg:h-60">
              <li class="flex flex-row">
<!--                <a @click.prevent="showPopup" href="#" class="flex flex-row items-center px-2 lg:px-6 xl:px-12 relative" v-if="!$auth.isAuthenticated">-->
<!--                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">-->
<!--                    <path d="M9.875 5.75H9.3125V4.0625C9.3125 2.11719 7.69531 0.5 5.75 0.5C3.78125 0.5 2.1875 2.11719 2.1875 4.0625V5.75H1.625C0.992188 5.75 0.5 6.26562 0.5 6.875V11.375C0.5 12.0078 0.992188 12.5 1.625 12.5H9.875C10.4844 12.5 11 12.0078 11 11.375V6.875C11 6.26562 10.4844 5.75 9.875 5.75ZM7.4375 5.75H4.0625V4.0625C4.0625 3.14844 4.8125 2.375 5.75 2.375C6.66406 2.375 7.4375 3.14844 7.4375 4.0625V5.75Z" fill="#E8490F"/>-->
<!--                  </svg>-->
<!--                  Expo-->
<!--                </a>-->
                <router-link @click="hideMobileMenu" :to="$route.path === '/home' ? '/expo?from=header' : '/expo'" class="flex flex-row items-center px-2 lg:px-6 xl:px-12 relative">
                  Expo
                </router-link>
              </li>

              <li class="flex flex-row">
                <router-link @click="hideMobileMenu" :to="$route.path === '/home' ? '/experience?from=header' : '/experience'" class="flex flex-row items-center px-2 lg:px-6 xl:px-12 relative">
                  Experience
                </router-link>
              </li>
              <li class="flex flex-row">
                <router-link @click="hideMobileMenu" :to="$route.path === '/home' ? '/discover?from=header' : '/discover'" class="flex flex-row items-center px-2 lg:px-6 xl:px-12 relative">
                  Discover
                </router-link>
              </li>

            </ul>
          </nav>

          <div class="right-links space-x-3 md:space-x-12 flex flex-row items-center text-14 lg:text-16 text-grey-500 mb-px lg:mb-0.5">
            <router-link to="/profile" @click="hideMobileMenu" class="hidden md:flex icon-button focus:outline-none hover:text-primary-500 transition-colors flex-row items-center space-x-4" v-if="$auth.isAuthenticated">
              <span class="label hidden lg:block">My Profile</span>
              <svg aria-hidden="true" width="18" height="18" focusable="false" data-prefix="far" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg>
            </router-link>
<!--            <a href="#" @click.prevent="login" class="hidden md:flex icon-button focus:outline-none hover:text-primary-500 transition-colors flex-row items-center space-x-4" v-else>-->
<!--              <span class="label">Login</span>-->
<!--            </a>-->

            <button @click="logout" class="hidden md:flex icon-button focus:outline-none hover:text-primary-500 transition-colors flex-row items-center space-x-4" v-if="$auth.isAuthenticated">
              <span class="label hidden lg:block">Log out</span>
              <svg aria-hidden="true" width="20" height="20" focusable="false" data-prefix="fas" data-icon="sign-out" class="svg-inline--fa fa-sign-out fa-w-16 fill-current mt-px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z"></path></svg>
            </button>
            <button v-if="!$auth.isAuthenticated" @click="login" class="icon-button focus:outline-none hover:text-primary-500 transition-colors flex flex-row items-center space-x-4 text-white">
              <span class="label hidden md:block">Log in</span>
              <svg aria-hidden="true" width="24" height="24" focusable="false" data-prefix="fas" data-icon="sign-in" class="svg-inline--fa fa-sign-in fa-w-16 mt-px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M137.2 110.3l21.9-21.9c9.3-9.3 24.5-9.4 33.9-.1L344.9 239c9.5 9.4 9.5 24.7 0 34.1L193 423.7c-9.4 9.3-24.5 9.3-33.9-.1l-21.9-21.9c-9.7-9.7-9.3-25.4.8-34.7l77.6-71.1H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h191.5l-77.6-71.1c-10-9.1-10.4-24.9-.7-34.5zM512 352V160c0-53-43-96-96-96h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84c17.7 0 32 14.3 32 32v192c0 17.7-14.3 32-32 32h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84c53 0 96-43 96-96z"></path></svg>
            </button>
            <button @click="toggleMobileMenu" type="button" class="menu-button text-white md:hidden focus:outline-none hover:text-primary-500 transition-colors">
              <span class="bar bar1"></span>
              <span class="bar bar2"></span>
              <span class="bar bar3"></span>
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>



<script>
import Bus from '@/events/EventBus';
export default {
  name: 'AppHeader',
  props: {},
  data () {
    return {
      showMobileMenu: false,
      showConversations: process.env.VUE_APP_SHOW_CONVERSATIONS
    }
  },
  methods: {
    login() {
      if (!this.$cookies.get('signup-source')) {
        this.$cookies.set('signup-source', 'Connect',null,'/');
      }
      this.$auth.loginWithRedirect({ appState: { targetUrl: this.$route.fullPath } });
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    toggleMobileMenu () {
      this.showMobileMenu = !this.showMobileMenu
    },
    hideMobileMenu () {
      this.showMobileMenu = false
    },
    showPopup() {
      this.hideMobileMenu()
      Bus.$emit('show-locked-content-popup')
    }
  },
  watch:{
    $route (){
      this.showMobileMenu = false
    }
  }
}
</script>

<style scoped lang="scss">
.brand {
  svg, img {
    width: 150px;

    @media(min-width: 375px)
    {
      width: 200px;
    }

    @media(min-width: 500px)
    {
      width: 250px;
    }

    @media(min-width: 1024px)
    {
      width: 360px;
    }
  }
}

.main-nav {
  a {
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 5px;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: #E8490F;
      opacity: 0;
      width: 50%;
    }

    &:hover {
      &:after {
        height: 2px;
        opacity: 1;
      }
    }

    &.router-link-active, &.router-link-active:hover {
      &:after {
        height: 8px;
        opacity: 1;
      }
    }
  }
}

.menu-button {
  position: relative;
  width: 40px;
  height: 40px;

  .bar {
    display: block;
    position: absolute;
    left: 7px;
    right: 7px;
    height: 3px;
    background-color: currentColor;
    transition: all 0.3s;
    border-radius: 2px;
  }

  .bar1 {
    top: 10px;
  }

  .bar2 {
    top: 19px;
  }

  .bar3 {
    top: 28px;
  }
}

.mobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
  background-color: #000;
  color: #000;

  .inner {
    background-color: #fff;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

header.show-mobile-menu {
  .menu-button {
    .bar1, .bar3 {
      transform: translateX(-2px);
    }

    .bar2 {
      transform: translateX(2px);
    }
  }

  .mobile-menu {
    opacity: 1;
    visibility: visible;
  }
}

.mobile-menu {
  li a {
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 5px;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: #E8490F;
      opacity: 0;
      width: 90%;
    }

    &.router-link-active, &.router-link-active:hover {
      &:after {
        height: 8px;
        opacity: 1;
      }
    }
  }

  .mobile-nav {
    max-width: 300px;
    margin: 0 auto;
  }
}
</style>
